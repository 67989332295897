import React from 'react';

export default {
  input: {
    name: 'input',
    type: 'inputNode',
    default: {
      limit: 20,
      sql_limit: 0,
    },
    fields: [
      {
        fieldName: 'source',
        fieldText: '#1 Select the table you want to analyse',
        placeholder: 'Search tables...',
        fieldType: 'tableClick',
        optionType: 'tables',
        columnHeaders: [
          {
            classes: 'min-w-[44px] max-w-[44px] text-center',
            name: 'Type',
          },
          {
            classes: 'min-w-[150px] py-3 text-left',
            name: 'Source',
          },
          {
            classes: 'min-w-[150px] py-3 text-left',
            name: 'Name',
          },
        ],
        defaultValue: '',
        options: [],
      },
      {
        fieldName: 'sqlLimit',
        fieldText: 'Limit the dataset to X rows',
        placeholder: '10000',
        fieldType: 'text',
        size: 'small',
        advanced: true,
      },
      {
        fieldName: 'whereClause',
        fieldText: 'Add a "where"-clause when loading data',
        placeholder: '[NumberColumn] > 100',
        fieldType: 'text',
        size: 'small',
        advanced: true,
      },
    ],
  },
  output: {
    name: 'output',
    default: {
      limit: 20,
      sql_limit: 200,
    },
    fields: [
      {
        fieldName: 'dbId',
        fieldText: '#1 Select the Database you want to store the table on',
        fieldType: 'dropdown',
        optionType: 'databases',
        placeholder: 'Databases',
        options: [],
      },
      {
        fieldName: 'tableName',
        fieldText: '#2 Name your table',
        placeholder: 'm_MyNewModel',
        withtooltip: true,

        tooltip:
          "It's a good idea to add a prefix so you can easily identify your models from raw data",
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'outputOption',
        fieldText: '#3 Select how to handle output',
        fieldType: 'dropdown',
        optionType: 'manual',
        defaultValue: 'replace',
        placeholder: 'Choose Write Action',
        withtooltip: true,
        tooltip:
          'Overwrite means you create new tables at every update while Append adds the new table at the end of the existing table. See more in our documentation.',
        options: [
          {
            value: 'replace',
            label: 'Overwrite existing table',
          },
          {
            value: 'append',
            label: 'Append if table already exist',
          },
        ],
      },
      {
        fieldName: 'dbOutput',
        fieldText: 'Disable database output',
        fieldType: 'checkboxSelect',
        withtooltip: true,
        tooltip:
          'Disabling database output significantly increases the speed of the output process',
        optionType: 'manual',
        defaultValue: {},
        options: [
          {
            id: 1,
            label: 'I do not want to output this table to the database',
            value: 'disable',
          },
        ],
        advanced: true,
      },
    ],
  },
  formula: {
    name: 'formula',
    fields: [
      {
        fieldName: 'columnName',
        fieldText: '#1 Input the new column name',
        fieldType: 'text',
        placeholder: 'New_Column_Name',
        size: 'small',
        optionType: '',
        options: [
          {
            id: 1,
            name: 'connection1',
          },
        ],
      },
      {
        fieldName: 'formula',
        fieldText: '#2 Insert your calculation',
        fieldType: 'formula',
        size: 'small',
        placeholder: '[ColumnA]*2',
        withtooltip: true,
        tooltip: 'See our documentation for more information on how calculations work',
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  newtextcolumn: {
    name: 'newtextcolumn',
    fields: [
      {
        fieldName: 'columnName',
        fieldText: '#1 Input the new column name',
        placeholder: 'New_Column_Name',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'columnConfig',
        fieldText: '#2 Create the new column value',
        withtooltip: true,
        tooltip: 'Use hard brackets [] to access columns and double quote "" for text',
        placeholder: '[Column 1]+"_"+"Sales"',
        fieldType: 'formula',
        size: 'small',
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  filter: {
    name: 'filter',
    fields: [
      {
        fieldName: 'conditions',
        fieldText:
          '#1 Add a column you want to filter, a condition to filter with and a value to filter by. You can add AND and OR clauses if you want.',
        fieldType: 'modellingFilter',
        placeholder: 'None',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: [
          {
            column: '',
            condition: '',
            value: '',
            label: '',
          },
        ],
        buttonText: 'Add aggregation',
        rightOptions: [
          {
            value: '==',
            label: 'Equals (=)',
          },
          {
            value: '!=',
            label: 'Does not equal (!=)',
          },
          {
            value: '<',
            label: 'Less than (<)',
          },
          {
            value: '>',
            label: 'Larger than (>)',
          },
          {
            value: '<=',
            label: 'Less than or equal (<=)',
          },
          {
            value: '>=',
            label: 'Larger than or equal (>=)',
          },
          {
            value: 'contains',
            label: 'Contains',
          },
          {
            value: '!contains',
            label: 'Does not contain',
          },
          {
            value: 'startswith',
            label: 'Starts with',
          },
          {
            value: '!startswith',
            label: 'Does not Start with',
          },
          {
            value: 'endswith',
            label: 'Ends with',
          },
          {
            value: '!endswith',
            label: 'Does not end with',
          },
          // { value: 'null', label: 'Is Null' },
          // { value: 'notnull', label: 'Is NOT Null' },
          // { value: 'empty', label: 'Is empty' },
          // { value: 'notempty', label: 'Is NOT empty' },
        ],
      },
    ],
  },
  format: {
    name: 'format',
    fields: [
      {
        fieldName: 'columns',
        fieldText: '#1 Change the data types of your columns',
        fieldType: 'optionsTable',
        optionLabel: 'Columns',
        optionType: 'columns',
        options: 'dataframe',
        prefillValues: 'datatypes',
        defaultValue: {},
        placeholder: 'None',
        actionLabel: 'Data Type',
        secondHeader: 'Format',
        types: [
          {
            label: 'None',
            value: null,
          },
          {
            label: 'Text',
            value: 'string',
          },
          {
            label: 'Whole Number',
            value: 'int64',
          },
          {
            label: 'Decimal',
            value: 'float64',
          },
          {
            label: 'Datetime',
            value: 'datetime64[ns]',
          },
          {
            label: 'Date',
            value: 'date',
          },
          {
            label: 'Boolean',
            value: 'boolean',
          },
        ],
      },
    ],
  },
  rename: {
    name: 'rename',
    fields: [
      {
        fieldName: 'columns',
        fieldText: '#1 Change columns names by writing in the second column',
        fieldType: 'RenameColumns',
        defaultValue: {},
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  summarize: {
    name: 'summarize',
    fields: [
      {
        fieldName: 'groupby',
        fieldText: '#1 Select the column(s) to group by',
        fieldType: 'multidropdown',
        unknownColums: true,
        placeholder: 'Columns...',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
        optional: true,
      },
      {
        fieldName: 'columnNamesAgg',
        fieldText: '#2 Select column(s) to aggregate and their aggregation',
        fieldType: 'twoRowGroup',
        placeholder: 'None',
        actionLabel: 'Action',
        leftHeader: 'Column',
        rightHeader: 'Aggregation',
        leftKeyName: 'column',
        rightKeyName: 'aggregation',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: [
          {
            column: '',
            aggregation: '',
            label: 'Aggregation',
          },
        ],
        buttonText: 'Add aggregation',
        rightOptionType: 'manual',
        rightOptions: [
          {
            value: null,
            label: 'None',
          },
          {
            value: 'sum',
            label: 'Sum',
          },
          {
            value: 'concat',
            label: 'Concatenate',
          },
          {
            value: 'mean',
            label: 'Mean',
          },
          {
            value: 'count',
            label: 'Count',
          },
          {
            value: 'distinct',
            label: 'Count Distinct',
          },
          {
            value: 'std',
            label: 'Std. Deviation',
          },
          {
            value: 'first',
            label: 'First',
          },
          {
            value: 'last',
            label: 'Last',
          },
          {
            value: 'min',
            label: 'Min',
          },
          {
            value: 'max',
            label: 'Max',
          },
        ],
      },
    ],
  },
  unique: {
    name: 'unique',
    fields: [
      {
        fieldName: 'columnNames',
        fieldText: '#1 Select the columns that must be unique',
        fieldType: 'multidropdown',
        defaultValue: {},
        placeholder: 'Select columns',
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  ifelse: {
    name: 'ifelse',
    fields: [
      {
        fieldName: 'columnName',
        fieldText: '#1 Input a name for the new column',
        placeholder: 'New_Column_Name',

        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'statements',
        fieldText: '#2 Add your IF and ELSEIF statements',
        fieldType: 'conditional',
        placeholder: 'None',
        actionLabel: 'Action',
        secondHeader: 'Aggregation',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: [
          {
            column: '',
            aggregation: '',
            label: 'Original',
          },
        ],
      },
      // {
      //   fieldName: 'if',
      //   fieldText: '#2 If this column...',
      //   placeholder: 'Column',
      //   fieldType: 'dropdown',
      //   optionType: 'columns',
      //   options: 'dataframe',
      // },
      // {
      //   fieldName: 'is',
      //   fieldText: '#3 is equal/not equal/larger than...',
      //   placeholder: 'Condition',
      //   fieldType: 'dropdown',
      //   optionType: 'manual',
      //   options: [
      //     { value: '==', label: 'Equals (=)' },
      //     { value: '!=', label: 'Does not equal (!=)' },
      //     { value: '<', label: 'Less than (<)' },
      //     { value: '>', label: 'Larger than (>)' },
      //     { value: '<=', label: 'Less than or equal (<=)' },
      //     { value: '>=', label: 'Larger than or equal (>=)' },
      //   ],
      // },
      // {
      //   fieldName: 'this',
      //   fieldText: '#4 This value (text must be in double quotes " ")...',
      //   placeholder: '3',
      //   fieldType: 'formula',
      //   size: 'small',
      //   optionType: 'columns',
      //   options: 'dataframe',
      // },
      // {
      //   fieldName: 'then',
      //   fieldText: '#5 Then this (text must be in double quotes " ")...',
      //   fieldType: 'formula',
      //   placeholder: '"[SalesColumn]*2"',
      //   size: 'small',
      //   optionType: 'columns',
      //   options: 'dataframe',
      // },
      {
        fieldName: 'else',
        fieldText: '#3 Add your final ELSE statement',
        fieldType: 'formula',
        placeholder: '"Not sales_"+[SalesColumn)',
        size: 'small',
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  join: {
    name: 'join2',
    fields: [
      {
        fieldName: 'joinType',
        fieldText: '#1 Select join type',

        placeholder: 'Join type',
        fieldType: 'dropdown',
        optionType: 'manual',
        options: [
          {
            value: 'left',
            label:
              'Left: keep all rows from the left table and combine with the right table when possible',
          },
          {
            value: 'inner',
            label: 'Inner: only keep rows the can combine from both tables',
          },
          {
            value: 'right',
            label:
              'Right: keep all rows from the right table and combine with the left table when possible',
          },
        ],
      },
      {
        fieldName: 'joinColumns',
        fieldText: '#2 Select column(s) that are common in both tables',
        fieldType: 'twoRowGroup',
        placeholder: 'None',
        actionLabel: 'Action',
        leftHeader: 'Left table',
        rightHeader: 'Right table',
        secondHeader: 'Add columns to join on',
        optionType: 'columns',
        options: 'df_l',
        defaultValue: [
          {
            left: '',
            right: '',
            label: '',
          },
        ],
        buttonText: 'Add additional columns to join on',
        rightOptionType: 'columns',
        rightOptions: 'df_r',
      },
      // {
      //   fieldName: 'columnNames',
      //   fieldText: '#1 Select the columns you want to keep',
      //   fieldType: 'multiSelectSearch',
      //   defaultValue: {},
      //   optionType: 'multiColumns',
      //   options: ['df_l', 'df_r'],
      // },
    ],
  },
  // join: {
  //   name: 'join',
  //   fields: [
  //     {
  //       fieldName: 'joinType',
  //       fieldText: '#1 Select join type',

  //       placeholder: 'Join type',
  //       fieldType: 'dropdown',
  //       optionType: 'manual',
  //       options: [
  //         {
  //           value: 'left',
  //           label:
  //             'Left: keep all rows from the left table and combine with the right table when possible',
  //         },
  //         { value: 'inner', label: 'Inner: only keep rows the can combine from both tables' },
  //         {
  //           value: 'right',
  //           label:
  //             'Right: keep all rows from the right table and combine with the left table when possible',
  //         },
  //       ],
  //     },
  //     {
  //       fieldName: 'leftId',
  //       fieldText: '#2 Select the combine ID in left table',
  //       placeholder: 'Left Column',
  //       fieldType: 'dropdown',
  //       optionType: 'columns',
  //       options: 'df_l',
  //     },
  //     {
  //       fieldName: 'rightId',
  //       fieldText: '#3 Select the combine ID in right table',
  //       placeholder: 'Right Column',
  //       fieldType: 'dropdown',
  //       optionType: 'columns',
  //       options: 'df_r',
  //     },
  //     // {
  //     //   fieldName: 'columnNames',
  //     //   fieldText: '#1 Select the columns you want to keep',
  //     //   fieldType: 'multiSelectSearch',
  //     //   defaultValue: {},
  //     //   optionType: 'multiColumns',
  //     //   options: ['df_l', 'df_r'],
  //     // },
  //   ],
  // },
  select: {
    name: 'select',
    fields: [
      {
        fieldName: 'columnNames',
        fieldText: '#1 Select the columns you want to keep',
        fieldType: 'multiSelectSearch',
        defaultValue: {},
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  sort: {
    name: 'sort',
    fields: [
      {
        fieldName: 'columns',
        fieldText: '1# Choose columns to sort and how to sort them',
        fieldType: 'optionsTable',
        optionLabel: 'Columns',
        optionType: 'columns',
        options: 'dataframe',
        actionLabel: 'Order',
        placeholder: 'None',
        secondHeader: 'Sort By',
        types: [
          {
            value: null,
            label: 'None',
          },
          {
            label: 'Ascending',
            value: true,
          },
          {
            label: 'Descending',
            value: false,
          },
        ],
      },
    ],
  },
  union: {
    name: 'union',
    fields: [
      {
        fieldName: 'description',
        fieldText:
          'The Stack Action is configured by column names. In other words, columns are stacked on top of each other if they have the same name. So make sure the columns would want to stack have an identical naming - otherwise it will fail.',
        fieldType: 'description',
      },
    ],
  },
  diff: {
    name: 'diff',
    fields: [
      {
        fieldName: 'columnNames',
        fieldText: '#1 Select the columns on which you want to get a vertical difference column',
        fieldType: 'multidropdown',
        placeholder: 'Select columns...',
        defaultValue: {},
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'prefix',
        fieldText: '#2 Input a prefix for new columns',
        placeholder: 'Test_',
        withtooltip: true,
        tooltip: "We'll use the existing column as the base and add the prefix in front of it",
        fieldType: 'text',
        size: 'small',
      },
    ],
  },
  dateFormat: {
    name: 'dateFormat',
    fields: [
      {
        fieldName: 'columnName',
        fieldText: '#1 Select the date column you want to format',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'columnNames',
        fieldText: '#2 Select the formats you want to output',
        fieldType: 'multidropdown',
        placeholder: 'Select output formats',
        optionType: 'manual',
        defaultValue: {},
        options: [
          {
            value: 'date',
            label: 'Date',
          },
          {
            value: 'hour',
            label: 'Hour',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'weekday',
            label: 'Weekday',
          },
          {
            value: 'month',
            label: 'Month',
          },
          {
            value: 'monthname',
            label: 'Monthname',
          },
          {
            value: 'firstdayofmonth',
            label: 'First day of month',
          },
          {
            value: 'lastdayofmonth',
            label: 'Last day of month',
          },
          {
            value: 'quarter',
            label: 'Quarter',
          },
          {
            value: 'yearhalf',
            label: 'Year Half',
          },
          {
            value: 'year',
            label: 'Year',
          },
          {
            value: 'time',
            label: 'Time',
          },
        ],
      },
    ],
  },
  parseJson: {
    name: 'parseJson',
    fields: [
      {
        fieldName: 'idColumn',
        fieldText: '#1 Select ID Column',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'columnName',
        fieldText: '#2 Select column to parse',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },

      {
        fieldName: 'level',
        fieldText: '#3 Select the number of levels you want to parse',
        placeholder: '3',
        withtooltip: true,
        tooltip: 'Use minus (-) to subtract',
        fieldType: 'formula',
        size: 'small',
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  transpose: {
    name: 'transpose',
    fields: [
      {
        fieldName: 'keyfields',
        fieldText: '#1 Select the columns you want to hold constant',
        fieldType: 'multidropdown',
        unknownColums: true,
        placeholder: 'Select columns...',
        optionType: 'columns',
        options: 'dataframe',
        optional: true,
        defaultValue: {},
      },
      {
        fieldName: 'tfields',
        fieldText: '#2 Select the columns you want to transform to rows',
        fieldType: 'multidropdown',
        unknownColums: true,
        placeholder: 'Select columns...',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
      },
    ],
  },
  pivot: {
    name: 'pivot',
    fields: [
      {
        fieldName: 'keyfields',
        fieldText: '#1 Select columns you want to hold constant',
        fieldType: 'multidropdown',
        unknownColums: true,
        placeholder: 'Select columns...',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
      },
      {
        fieldName: 'header',
        fieldText: '#2 Select columns you would like transformed to headers',
        fieldType: 'dropdown',
        placeholder: 'None',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
      },
      {
        fieldName: 'value',
        fieldText: '#3 Select value field (rows for the new headers in #2)',
        fieldType: 'dropdown',
        placeholder: 'None',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
      },
      {
        fieldName: 'aggfunc',
        fieldText: '#4 Select how to aggregate the value field',
        fieldType: 'dropdown',
        placeholder: 'None',
        optionType: 'manual',
        optionDatatypes: ['float64', 'int64', 'datetime64[ns]'],
        options: [
          {
            value: null,
            label: 'None',
          },
          {
            value: 'sum',
            label: 'Sum',
          },
          {
            value: 'mean',
            label: 'Mean',
          },
          {
            value: 'count',
            label: 'Count',
          },
          {
            value: 'concat',
            label: 'Concatenate',
          },
          {
            value: 'std',
            label: 'Std. Deviation',
          },
          {
            value: 'first',
            label: 'First',
          },
          {
            value: 'last',
            label: 'Last',
          },
          {
            value: 'min',
            label: 'Min',
          },
          {
            value: 'max',
            label: 'Max',
          },
        ],
      },
    ],
  },
  datediff: {
    name: 'datediff',
    fields: [
      {
        fieldName: 'columnOne',
        fieldText: '#1 Select the latest date',
        placeholder: 'Date Column One',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'columnTwo',
        fieldText: '#2 Select the date column you want to compare with',
        placeholder: 'Date Column Two',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'unit',
        fieldText: '#3 Select the unit you want the difference in',
        placeholder: 'Unit (seconds, minutes, hours, days)',
        fieldType: 'radio',
        optionType: 'manual',
        options: [
          {
            value: 'seconds',
            label: 'Seconds',
          },
          {
            value: 'minutes',
            label: 'Minutes',
          },
          {
            value: 'hours',
            label: 'Hours',
          },
          {
            value: 'days',
            label: 'Days',
          },
          {
            value: 'months',
            label: 'Months',
          },
          {
            value: 'years',
            label: 'Years',
          },
        ],
      },
    ],
  },
  datemath: {
    name: 'datemath',
    fields: [
      {
        fieldName: 'columnName',
        fieldText: '#1 Name your new column',
        placeholder: 'Date_plus_365_days',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'column',
        fieldText: '#2 Select the date column you want to use',
        placeholder: 'Date Column',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'unit',
        fieldText: '#3 Select the unit you want to add/subtract',

        placeholder: 'Unit (seconds, minutes, hours, days)',
        fieldType: 'radio',
        optionType: 'manual',
        options: [
          {
            value: 'seconds',
            label: 'Seconds',
          },
          {
            value: 'minutes',
            label: 'Minutes',
          },
          {
            value: 'hours',
            label: 'Hours',
          },
          {
            value: 'days',
            label: 'Days',
          },
          {
            value: 'weekdays',
            label: 'Weekdays (no weekends)',
          },
          {
            value: 'months',
            label: 'Months',
          },
          {
            value: 'years',
            label: 'Years',
          },
        ],
      },
      {
        fieldName: 'number',
        fieldText: '#4 Input the number of units',
        placeholder: '365',
        withtooltip: true,
        tooltip: 'Use minus (-) to subtract',
        fieldType: 'formula',
        size: 'small',
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  compare: {
    name: 'compare',
    fields: [
      {
        fieldName: 'columnNames',
        fieldText: '#1 Select the column(s) with the value you want to compare with',
        placeholder: 'Select Columns',
        fieldType: 'multidropdown',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
      },
      {
        fieldName: 'shiftValue',
        fieldText: '#2 Input the number of rows you want to go back',
        placeholder: '365',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'groupBy',
        fieldText: 'Group by a column',
        placeholder: 'Group',
        fieldType: 'multidropdown',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
        advanced: true,
      },
    ],
  },
  append: {
    name: 'append',
    fields: [
      {
        fieldName: 'description',
        fieldText: "You don't have to do any configuration for the Append Action.",
        fieldType: 'description',
      },
    ],
  },
  limit: {
    name: 'limit',
    fields: [
      {
        fieldName: 'rowLimit',
        fieldText: '#1 How many rows do you want to keep?',
        placeholder: '100',
        fieldType: 'text',
        size: 'small',
      },
    ],
  },
  splitColumns: {
    name: 'splitColumns',
    fields: [
      {
        fieldName: 'column',
        fieldText: '#1 Select the column you want to split',
        placeholder: 'Column to split',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'seperator',
        fieldText: '#2 At what character/delimiter do you want to split?',
        placeholder: ',',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'direction',
        fieldText: '#3 How do you want to split',
        placeholder: 'Columns or Rows',
        fieldType: 'radio',
        optionType: 'manual',
        options: [
          {
            value: 'columns',
            label: 'To columns',
          },
          {
            value: 'rows',
            label: 'To Rows',
          },
        ],
      },
      {
        fieldName: 'maxsplits',
        fieldText: '#4 How many columns do you want?',
        placeholder: '4',
        fieldType: 'text',
        size: 'small',
        advanced: true,
      },
      {
        fieldName: 'directionforsplit',
        fieldText: '#5 Select the direction you want to split',
        placeholder: 'Select action',
        fieldType: 'radio',
        optionType: 'manual',
        options: [
          {
            value: 'fromleft',
            label: 'Left to right',
          },
          {
            value: 'fromright',
            label: 'Right to left',
          },
        ],
        defaultValue: {},
        advanced: true,
      },
    ],
  },
  texttransform: {
    name: 'texttransform',
    fields: [
      {
        fieldName: 'columns',
        fieldText: '#1 Select the column(s) you want to alter',
        placeholder: 'Select Column',
        fieldType: 'multidropdown',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
      },
      {
        fieldName: 'operations',
        fieldText: '#2 Select how you want to alter the column(s)',
        placeholder: 'Select action',
        fieldType: 'radiotext',
        optionType: 'manual',
        options: [
          {
            value: 'uppercase',
            label: 'Upper Case',
          },
          {
            value: 'lowercase',
            label: 'Lower Case',
          },
          {
            value: 'titlecase',
            label: 'Title Case',
          },
          {
            value: 'left',
            label: 'Left',
            extraValue: 'true',
            extraFormat: 'number',
          },
          {
            value: 'right',
            label: 'Right',
            extraValue: 'true',
            extraFormat: 'number',
          },
          {
            value: 'length',
            label: 'Length',
            extraValue: 'true',
            extraFormat: 'number',
          },
          {
            value: 'round',
            label: 'Round',
            extraValue: 'true',
            extraFormat: 'number',
          },
          {
            value: 'ceil',
            label: 'Ceil',
          },
          {
            value: 'floor',
            label: 'Floor',
          },
          {
            value: 'trim',
            label: 'Trim',
          },
          {
            value: 'escape',
            label: 'Escape special characters',
          },
          {
            value: 'htmltotext',
            label: 'HTML to text',
          },
        ],
        defaultValue: {},
      },
    ],
  },
  rowid: {
    name: 'rowid',
    fields: [
      {
        fieldName: 'description',
        fieldText:
          'This Action simply adds a RowID column to your dataset. No need for any configuration.',
        fieldType: 'description',
      },
      {
        fieldName: 'groupBy',
        fieldText: 'Group by a column',
        placeholder: 'Group',
        fieldType: 'multidropdown',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
        advanced: true,
      },
    ],
  },
  runninginterval: {
    name: 'runninginterval',
    fields: [
      {
        fieldName: 'columnName',
        fieldText: '#1 Name your new column',
        placeholder: 'Rolling_Revenue',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'column',
        fieldText: '#2 Select the column you want a running interval calculation on',
        placeholder: 'Column',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'offset',
        fieldText: '#3 At what relative row do you want to begin (offset)?',
        withtooltip: true,
        tooltip:
          'Imagine your on row 10. If you want you running interval to start at row 3, you should input -7',
        placeholder: '-365',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'periods',
        fieldText: '#4 From your offset, input the number of rows to look ahead?',
        placeholder: '90',
        withtooltip: true,
        tooltip:
          'Imagine your offset begins at relative row 7 and you want to sum row 7 to 10. Then input 3 here. Value must be equal to or larger than 0 ',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'function',
        fieldText: '#5 How do you want to aggregate the interval?',
        placeholder: 'Aggregate',
        fieldType: 'dropdown',
        optionType: 'manual',
        options: [
          {
            value: null,
            label: 'None',
          },
          {
            value: 'sum',
            label: 'Sum',
          },
          {
            value: 'mean',
            label: 'Mean (average)',
          },
          {
            value: 'count',
            label: 'Count',
          },
          {
            value: 'std',
            label: 'Std. Deviation',
          },
          {
            value: 'min',
            label: 'Min',
          },
          {
            value: 'max',
            label: 'Max',
          },
        ],
      },
    ],
  },
  filler: {
    name: 'filler',
    fields: [
      {
        fieldName: 'columns',
        fieldText: '#1 Select the column(s) you want to fill',
        placeholder: 'Select Columns',
        fieldType: 'multidropdown',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
      },
      {
        fieldName: 'strategy',
        fieldText: '#2 Select the strategy for filling',
        fieldType: 'radiotext',
        optionType: 'manual',
        options: [
          {
            value: 'value',
            label: 'Value',
            extraValue: 'true',
          },
          {
            value: 'zero',
            label: 'Zero',
          },
          {
            value: 'forward',
            label: 'Forward',
          },
          {
            value: 'backward',
            label: 'Backward',
          },
        ],
        defaultValue: {},
      },
    ],
  },
  cumulCalc: {
    name: 'cumulCalc',
    fields: [
      {
        fieldName: 'columnNamesAgg',
        fieldText: '#1 Select column(s) and their cumulative calculation',
        fieldType: 'twoRowGroup',
        placeholder: 'None',
        actionLabel: 'Action',
        secondHeader: 'Aggregation',
        optionType: 'columns',
        leftHeader: 'Column',
        rightHeader: 'Cumulative Action',
        leftKeyName: 'column',
        rightKeyName: 'aggregation',
        options: 'dataframe',
        defaultValue: [
          {
            column: '',
            aggregation: '',
            label: 'Aggregation',
          },
        ],
        buttonText: 'Add aggregation',
        rightOptionType: 'manual',
        rightOptions: [
          {
            value: null,
            label: 'None',
          },
          {
            value: 'cumsum',
            label: 'Cumulative Sum',
          },
          {
            value: 'cumavg',
            label: 'Cumulative Average',
          },
          {
            value: 'cummin',
            label: 'Cumulative Min',
          },
          {
            value: 'cummax',
            label: 'Cumulative Average',
          },
        ],
      },
      {
        fieldName: 'groupby',
        fieldText: 'Select the column(s) you want to group by',
        placeholder: 'Select Columns',
        fieldType: 'multidropdown',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
        advanced: true,
      },
      {
        fieldName: 'sortcolumns',
        fieldText: 'Choose columns to sort and how to sort them',
        fieldType: 'optionsTable',
        optionLabel: 'Columns',
        advanced: true,
        optionType: 'columns',
        options: 'dataframe',
        actionLabel: 'Order',
        placeholder: 'None',
        secondHeader: 'Sort By',
        types: [
          {
            value: null,
            label: 'None',
          },
          {
            label: 'Ascending',
            value: true,
          },
          {
            label: 'Descending',
            value: false,
          },
        ],
      },
    ],
  },
  replace: {
    name: 'replace',
    fields: [
      {
        fieldName: 'columns',
        fieldText: '#1 Select the column(s) you want to replace a value in',
        placeholder: 'Select Columns',
        fieldType: 'multidropdown',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
        withtooltip: true,
        tooltip: 'You can only replace values in text column',
      },
      {
        fieldName: 'valueToReplace',
        fieldText: '#2 What do you want to replace?',
        fieldType: 'combobox',
        placeholder: 'Sales',
        defaultValue: '',
        size: 'small',
        withtooltip: true,
        tooltip: 'Case sensitive',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'replacement',
        fieldText: '#3 What do you want to replace it with?',
        fieldType: 'combobox',
        placeholder: 'Revenue',
        defaultValue: '',
        size: 'small',
        withtooltip: true,
        tooltip: 'Case sensitive',
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  createRows: {
    name: 'createRows',
    fields: [
      {
        fieldName: 'splitNumber',
        fieldText:
          '#1 Select an integer/number column with the number of rows you want to create for that given row',
        placeholder: 'Select Columns',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
        withtooltip: false,
        tooltip: '',
      },
      {
        fieldName: 'splitColumns',
        fieldText: '#2 Select the column(s) you want to disaggregate',
        placeholder: 'Select Columns',
        fieldType: 'multidropdown',
        optionType: 'columns',
        options: 'dataframe',
        defaultValue: {},
        optional: true,
      },
    ],
  },
  today: {
    name: 'today',
    fields: [
      {
        fieldName: 'columnName',
        fieldText: '#1 Name your new column',
        fieldType: 'text',
        defaultValue: 'Now',
        withtooltip: false,
        tooltip: '',
      },
      {
        fieldName: 'timezone',
        fieldText: '#2 Select the timezone you want the current date and time in',
        fieldType: 'tableClick',
        optionType: 'manual',
        placeholder: 'Search timezones...',
        columnHeaders: [
          {
            classes: 'py-3 text-left',
            name: 'Timezone',
          },
        ],
        options: [
          {
            value: 'Africa/Abidjan',
            label: 'Africa/Abidjan',
          },
          {
            value: 'Africa/Accra',
            label: 'Africa/Accra',
          },
          {
            value: 'Africa/Addis_Ababa',
            label: 'Africa/Addis_Ababa',
          },
          {
            value: 'Africa/Algiers',
            label: 'Africa/Algiers',
          },
          {
            value: 'Africa/Asmara',
            label: 'Africa/Asmara',
          },
          {
            value: 'Africa/Asmera',
            label: 'Africa/Asmera',
          },
          {
            value: 'Africa/Bamako',
            label: 'Africa/Bamako',
          },
          {
            value: 'Africa/Bangui',
            label: 'Africa/Bangui',
          },
          {
            value: 'Africa/Banjul',
            label: 'Africa/Banjul',
          },
          {
            value: 'Africa/Bissau',
            label: 'Africa/Bissau',
          },
          {
            value: 'Africa/Blantyre',
            label: 'Africa/Blantyre',
          },
          {
            value: 'Africa/Brazzaville',
            label: 'Africa/Brazzaville',
          },
          {
            value: 'Africa/Bujumbura',
            label: 'Africa/Bujumbura',
          },
          {
            value: 'Africa/Cairo',
            label: 'Africa/Cairo',
          },
          {
            value: 'Africa/Casablanca',
            label: 'Africa/Casablanca',
          },
          {
            value: 'Africa/Ceuta',
            label: 'Africa/Ceuta',
          },
          {
            value: 'Africa/Conakry',
            label: 'Africa/Conakry',
          },
          {
            value: 'Africa/Dakar',
            label: 'Africa/Dakar',
          },
          {
            value: 'Africa/Dar_es_Salaam',
            label: 'Africa/Dar_es_Salaam',
          },
          {
            value: 'Africa/Djibouti',
            label: 'Africa/Djibouti',
          },
          {
            value: 'Africa/Douala',
            label: 'Africa/Douala',
          },
          {
            value: 'Africa/El_Aaiun',
            label: 'Africa/El_Aaiun',
          },
          {
            value: 'Africa/Freetown',
            label: 'Africa/Freetown',
          },
          {
            value: 'Africa/Gaborone',
            label: 'Africa/Gaborone',
          },
          {
            value: 'Africa/Harare',
            label: 'Africa/Harare',
          },
          {
            value: 'Africa/Johannesburg',
            label: 'Africa/Johannesburg',
          },
          {
            value: 'Africa/Juba',
            label: 'Africa/Juba',
          },
          {
            value: 'Africa/Kampala',
            label: 'Africa/Kampala',
          },
          {
            value: 'Africa/Khartoum',
            label: 'Africa/Khartoum',
          },
          {
            value: 'Africa/Kigali',
            label: 'Africa/Kigali',
          },
          {
            value: 'Africa/Kinshasa',
            label: 'Africa/Kinshasa',
          },
          {
            value: 'Africa/Lagos',
            label: 'Africa/Lagos',
          },
          {
            value: 'Africa/Libreville',
            label: 'Africa/Libreville',
          },
          {
            value: 'Africa/Lome',
            label: 'Africa/Lome',
          },
          {
            value: 'Africa/Luanda',
            label: 'Africa/Luanda',
          },
          {
            value: 'Africa/Lubumbashi',
            label: 'Africa/Lubumbashi',
          },
          {
            value: 'Africa/Lusaka',
            label: 'Africa/Lusaka',
          },
          {
            value: 'Africa/Malabo',
            label: 'Africa/Malabo',
          },
          {
            value: 'Africa/Maputo',
            label: 'Africa/Maputo',
          },
          {
            value: 'Africa/Maseru',
            label: 'Africa/Maseru',
          },
          {
            value: 'Africa/Mbabane',
            label: 'Africa/Mbabane',
          },
          {
            value: 'Africa/Mogadishu',
            label: 'Africa/Mogadishu',
          },
          {
            value: 'Africa/Monrovia',
            label: 'Africa/Monrovia',
          },
          {
            value: 'Africa/Nairobi',
            label: 'Africa/Nairobi',
          },
          {
            value: 'Africa/Ndjamena',
            label: 'Africa/Ndjamena',
          },
          {
            value: 'Africa/Niamey',
            label: 'Africa/Niamey',
          },
          {
            value: 'Africa/Nouakchott',
            label: 'Africa/Nouakchott',
          },
          {
            value: 'Africa/Ouagadougou',
            label: 'Africa/Ouagadougou',
          },
          {
            value: 'Africa/Porto-Novo',
            label: 'Africa/Porto-Novo',
          },
          {
            value: 'Africa/Sao_Tome',
            label: 'Africa/Sao_Tome',
          },
          {
            value: 'Africa/Timbuktu',
            label: 'Africa/Timbuktu',
          },
          {
            value: 'Africa/Tripoli',
            label: 'Africa/Tripoli',
          },
          {
            value: 'Africa/Tunis',
            label: 'Africa/Tunis',
          },
          {
            value: 'Africa/Windhoek',
            label: 'Africa/Windhoek',
          },
          {
            value: 'America/Adak',
            label: 'America/Adak',
          },
          {
            value: 'America/Anchorage',
            label: 'America/Anchorage',
          },
          {
            value: 'America/Anguilla',
            label: 'America/Anguilla',
          },
          {
            value: 'America/Antigua',
            label: 'America/Antigua',
          },
          {
            value: 'America/Araguaina',
            label: 'America/Araguaina',
          },
          {
            value: 'America/Argentina/Buenos_Aires',
            label: 'America/Argentina/Buenos_Aires',
          },
          {
            value: 'America/Argentina/Catamarca',
            label: 'America/Argentina/Catamarca',
          },
          {
            value: 'America/Argentina/ComodRivadavia',
            label: 'America/Argentina/ComodRivadavia',
          },
          {
            value: 'America/Argentina/Cordoba',
            label: 'America/Argentina/Cordoba',
          },
          {
            value: 'America/Argentina/Jujuy',
            label: 'America/Argentina/Jujuy',
          },
          {
            value: 'America/Argentina/La_Rioja',
            label: 'America/Argentina/La_Rioja',
          },
          {
            value: 'America/Argentina/Mendoza',
            label: 'America/Argentina/Mendoza',
          },
          {
            value: 'America/Argentina/Rio_Gallegos',
            label: 'America/Argentina/Rio_Gallegos',
          },
          {
            value: 'America/Argentina/Salta',
            label: 'America/Argentina/Salta',
          },
          {
            value: 'America/Argentina/San_Juan',
            label: 'America/Argentina/San_Juan',
          },
          {
            value: 'America/Argentina/San_Luis',
            label: 'America/Argentina/San_Luis',
          },
          {
            value: 'America/Argentina/Tucuman',
            label: 'America/Argentina/Tucuman',
          },
          {
            value: 'America/Argentina/Ushuaia',
            label: 'America/Argentina/Ushuaia',
          },
          {
            value: 'America/Aruba',
            label: 'America/Aruba',
          },
          {
            value: 'America/Asuncion',
            label: 'America/Asuncion',
          },
          {
            value: 'America/Atikokan',
            label: 'America/Atikokan',
          },
          {
            value: 'America/Atka',
            label: 'America/Atka',
          },
          {
            value: 'America/Bahia',
            label: 'America/Bahia',
          },
          {
            value: 'America/Bahia_Banderas',
            label: 'America/Bahia_Banderas',
          },
          {
            value: 'America/Barbados',
            label: 'America/Barbados',
          },
          {
            value: 'America/Belem',
            label: 'America/Belem',
          },
          {
            value: 'America/Belize',
            label: 'America/Belize',
          },
          {
            value: 'America/Blanc-Sablon',
            label: 'America/Blanc-Sablon',
          },
          {
            value: 'America/Boa_Vista',
            label: 'America/Boa_Vista',
          },
          {
            value: 'America/Bogota',
            label: 'America/Bogota',
          },
          {
            value: 'America/Boise',
            label: 'America/Boise',
          },
          {
            value: 'America/Buenos_Aires',
            label: 'America/Buenos_Aires',
          },
          {
            value: 'America/Cambridge_Bay',
            label: 'America/Cambridge_Bay',
          },
          {
            value: 'America/Campo_Grande',
            label: 'America/Campo_Grande',
          },
          {
            value: 'America/Cancun',
            label: 'America/Cancun',
          },
          {
            value: 'America/Caracas',
            label: 'America/Caracas',
          },
          {
            value: 'America/Catamarca',
            label: 'America/Catamarca',
          },
          {
            value: 'America/Cayenne',
            label: 'America/Cayenne',
          },
          {
            value: 'America/Cayman',
            label: 'America/Cayman',
          },
          {
            value: 'America/Chicago',
            label: 'America/Chicago',
          },
          {
            value: 'America/Chihuahua',
            label: 'America/Chihuahua',
          },
          {
            value: 'America/Coral_Harbour',
            label: 'America/Coral_Harbour',
          },
          {
            value: 'America/Cordoba',
            label: 'America/Cordoba',
          },
          {
            value: 'America/Costa_Rica',
            label: 'America/Costa_Rica',
          },
          {
            value: 'America/Creston',
            label: 'America/Creston',
          },
          {
            value: 'America/Cuiaba',
            label: 'America/Cuiaba',
          },
          {
            value: 'America/Curacao',
            label: 'America/Curacao',
          },
          {
            value: 'America/Danmarkshavn',
            label: 'America/Danmarkshavn',
          },
          {
            value: 'America/Dawson',
            label: 'America/Dawson',
          },
          {
            value: 'America/Dawson_Creek',
            label: 'America/Dawson_Creek',
          },
          {
            value: 'America/Denver',
            label: 'America/Denver',
          },
          {
            value: 'America/Detroit',
            label: 'America/Detroit',
          },
          {
            value: 'America/Dominica',
            label: 'America/Dominica',
          },
          {
            value: 'America/Edmonton',
            label: 'America/Edmonton',
          },
          {
            value: 'America/Eirunepe',
            label: 'America/Eirunepe',
          },
          {
            value: 'America/El_Salvador',
            label: 'America/El_Salvador',
          },
          {
            value: 'America/Ensenada',
            label: 'America/Ensenada',
          },
          {
            value: 'America/Fort_Nelson',
            label: 'America/Fort_Nelson',
          },
          {
            value: 'America/Fort_Wayne',
            label: 'America/Fort_Wayne',
          },
          {
            value: 'America/Fortaleza',
            label: 'America/Fortaleza',
          },
          {
            value: 'America/Glace_Bay',
            label: 'America/Glace_Bay',
          },
          {
            value: 'America/Godthab',
            label: 'America/Godthab',
          },
          {
            value: 'America/Goose_Bay',
            label: 'America/Goose_Bay',
          },
          {
            value: 'America/Grand_Turk',
            label: 'America/Grand_Turk',
          },
          {
            value: 'America/Grenada',
            label: 'America/Grenada',
          },
          {
            value: 'America/Guadeloupe',
            label: 'America/Guadeloupe',
          },
          {
            value: 'America/Guatemala',
            label: 'America/Guatemala',
          },
          {
            value: 'America/Guayaquil',
            label: 'America/Guayaquil',
          },
          {
            value: 'America/Guyana',
            label: 'America/Guyana',
          },
          {
            value: 'America/Halifax',
            label: 'America/Halifax',
          },
          {
            value: 'America/Havana',
            label: 'America/Havana',
          },
          {
            value: 'America/Hermosillo',
            label: 'America/Hermosillo',
          },
          {
            value: 'America/Indiana/Indianapolis',
            label: 'America/Indiana/Indianapolis',
          },
          {
            value: 'America/Indiana/Knox',
            label: 'America/Indiana/Knox',
          },
          {
            value: 'America/Indiana/Marengo',
            label: 'America/Indiana/Marengo',
          },
          {
            value: 'America/Indiana/Petersburg',
            label: 'America/Indiana/Petersburg',
          },
          {
            value: 'America/Indiana/Tell_City',
            label: 'America/Indiana/Tell_City',
          },
          {
            value: 'America/Indiana/Vevay',
            label: 'America/Indiana/Vevay',
          },
          {
            value: 'America/Indiana/Vincennes',
            label: 'America/Indiana/Vincennes',
          },
          {
            value: 'America/Indiana/Winamac',
            label: 'America/Indiana/Winamac',
          },
          {
            value: 'America/Indianapolis',
            label: 'America/Indianapolis',
          },
          {
            value: 'America/Inuvik',
            label: 'America/Inuvik',
          },
          {
            value: 'America/Iqaluit',
            label: 'America/Iqaluit',
          },
          {
            value: 'America/Jamaica',
            label: 'America/Jamaica',
          },
          {
            value: 'America/Jujuy',
            label: 'America/Jujuy',
          },
          {
            value: 'America/Juneau',
            label: 'America/Juneau',
          },
          {
            value: 'America/Kentucky/Louisville',
            label: 'America/Kentucky/Louisville',
          },
          {
            value: 'America/Kentucky/Monticello',
            label: 'America/Kentucky/Monticello',
          },
          {
            value: 'America/Knox_IN',
            label: 'America/Knox_IN',
          },
          {
            value: 'America/Kralendijk',
            label: 'America/Kralendijk',
          },
          {
            value: 'America/La_Paz',
            label: 'America/La_Paz',
          },
          {
            value: 'America/Lima',
            label: 'America/Lima',
          },
          {
            value: 'America/Los_Angeles',
            label: 'America/Los_Angeles',
          },
          {
            value: 'America/Louisville',
            label: 'America/Louisville',
          },
          {
            value: 'America/Lower_Princes',
            label: 'America/Lower_Princes',
          },
          {
            value: 'America/Maceio',
            label: 'America/Maceio',
          },
          {
            value: 'America/Managua',
            label: 'America/Managua',
          },
          {
            value: 'America/Manaus',
            label: 'America/Manaus',
          },
          {
            value: 'America/Marigot',
            label: 'America/Marigot',
          },
          {
            value: 'America/Martinique',
            label: 'America/Martinique',
          },
          {
            value: 'America/Matamoros',
            label: 'America/Matamoros',
          },
          {
            value: 'America/Mazatlan',
            label: 'America/Mazatlan',
          },
          {
            value: 'America/Mendoza',
            label: 'America/Mendoza',
          },
          {
            value: 'America/Menominee',
            label: 'America/Menominee',
          },
          {
            value: 'America/Merida',
            label: 'America/Merida',
          },
          {
            value: 'America/Metlakatla',
            label: 'America/Metlakatla',
          },
          {
            value: 'America/Mexico_City',
            label: 'America/Mexico_City',
          },
          {
            value: 'America/Miquelon',
            label: 'America/Miquelon',
          },
          {
            value: 'America/Moncton',
            label: 'America/Moncton',
          },
          {
            value: 'America/Monterrey',
            label: 'America/Monterrey',
          },
          {
            value: 'America/Montevideo',
            label: 'America/Montevideo',
          },
          {
            value: 'America/Montreal',
            label: 'America/Montreal',
          },
          {
            value: 'America/Montserrat',
            label: 'America/Montserrat',
          },
          {
            value: 'America/Nassau',
            label: 'America/Nassau',
          },
          {
            value: 'America/New_York',
            label: 'America/New_York',
          },
          {
            value: 'America/Nipigon',
            label: 'America/Nipigon',
          },
          {
            value: 'America/Nome',
            label: 'America/Nome',
          },
          {
            value: 'America/Noronha',
            label: 'America/Noronha',
          },
          {
            value: 'America/North_Dakota/Beulah',
            label: 'America/North_Dakota/Beulah',
          },
          {
            value: 'America/North_Dakota/Center',
            label: 'America/North_Dakota/Center',
          },
          {
            value: 'America/North_Dakota/New_Salem',
            label: 'America/North_Dakota/New_Salem',
          },
          {
            value: 'America/Nuuk',
            label: 'America/Nuuk',
          },
          {
            value: 'America/Ojinaga',
            label: 'America/Ojinaga',
          },
          {
            value: 'America/Panama',
            label: 'America/Panama',
          },
          {
            value: 'America/Pangnirtung',
            label: 'America/Pangnirtung',
          },
          {
            value: 'America/Paramaribo',
            label: 'America/Paramaribo',
          },
          {
            value: 'America/Phoenix',
            label: 'America/Phoenix',
          },
          {
            value: 'America/Port-au-Prince',
            label: 'America/Port-au-Prince',
          },
          {
            value: 'America/Port_of_Spain',
            label: 'America/Port_of_Spain',
          },
          {
            value: 'America/Porto_Acre',
            label: 'America/Porto_Acre',
          },
          {
            value: 'America/Porto_Velho',
            label: 'America/Porto_Velho',
          },
          {
            value: 'America/Puerto_Rico',
            label: 'America/Puerto_Rico',
          },
          {
            value: 'America/Punta_Arenas',
            label: 'America/Punta_Arenas',
          },
          {
            value: 'America/Rainy_River',
            label: 'America/Rainy_River',
          },
          {
            value: 'America/Rankin_Inlet',
            label: 'America/Rankin_Inlet',
          },
          {
            value: 'America/Recife',
            label: 'America/Recife',
          },
          {
            value: 'America/Regina',
            label: 'America/Regina',
          },
          {
            value: 'America/Resolute',
            label: 'America/Resolute',
          },
          {
            value: 'America/Rio_Branco',
            label: 'America/Rio_Branco',
          },
          {
            value: 'America/Rosario',
            label: 'America/Rosario',
          },
          {
            value: 'America/Santa_Isabel',
            label: 'America/Santa_Isabel',
          },
          {
            value: 'America/Santarem',
            label: 'America/Santarem',
          },
          {
            value: 'America/Santiago',
            label: 'America/Santiago',
          },
          {
            value: 'America/Santo_Domingo',
            label: 'America/Santo_Domingo',
          },
          {
            value: 'America/Sao_Paulo',
            label: 'America/Sao_Paulo',
          },
          {
            value: 'America/Scoresbysund',
            label: 'America/Scoresbysund',
          },
          {
            value: 'America/Shiprock',
            label: 'America/Shiprock',
          },
          {
            value: 'America/Sitka',
            label: 'America/Sitka',
          },
          {
            value: 'America/St_Barthelemy',
            label: 'America/St_Barthelemy',
          },
          {
            value: 'America/St_Johns',
            label: 'America/St_Johns',
          },
          {
            value: 'America/St_Kitts',
            label: 'America/St_Kitts',
          },
          {
            value: 'America/St_Lucia',
            label: 'America/St_Lucia',
          },
          {
            value: 'America/St_Thomas',
            label: 'America/St_Thomas',
          },
          {
            value: 'America/St_Vincent',
            label: 'America/St_Vincent',
          },
          {
            value: 'America/Swift_Current',
            label: 'America/Swift_Current',
          },
          {
            value: 'America/Tegucigalpa',
            label: 'America/Tegucigalpa',
          },
          {
            value: 'America/Thule',
            label: 'America/Thule',
          },
          {
            value: 'America/Thunder_Bay',
            label: 'America/Thunder_Bay',
          },
          {
            value: 'America/Tijuana',
            label: 'America/Tijuana',
          },
          {
            value: 'America/Toronto',
            label: 'America/Toronto',
          },
          {
            value: 'America/Tortola',
            label: 'America/Tortola',
          },
          {
            value: 'America/Vancouver',
            label: 'America/Vancouver',
          },
          {
            value: 'America/Virgin',
            label: 'America/Virgin',
          },
          {
            value: 'America/Whitehorse',
            label: 'America/Whitehorse',
          },
          {
            value: 'America/Winnipeg',
            label: 'America/Winnipeg',
          },
          {
            value: 'America/Yakutat',
            label: 'America/Yakutat',
          },
          {
            value: 'America/Yellowknife',
            label: 'America/Yellowknife',
          },
          {
            value: 'Antarctica/Casey',
            label: 'Antarctica/Casey',
          },
          {
            value: 'Antarctica/Davis',
            label: 'Antarctica/Davis',
          },
          {
            value: 'Antarctica/DumontDUrville',
            label: 'Antarctica/DumontDUrville',
          },
          {
            value: 'Antarctica/Macquarie',
            label: 'Antarctica/Macquarie',
          },
          {
            value: 'Antarctica/Mawson',
            label: 'Antarctica/Mawson',
          },
          {
            value: 'Antarctica/McMurdo',
            label: 'Antarctica/McMurdo',
          },
          {
            value: 'Antarctica/Palmer',
            label: 'Antarctica/Palmer',
          },
          {
            value: 'Antarctica/Rothera',
            label: 'Antarctica/Rothera',
          },
          {
            value: 'Antarctica/South_Pole',
            label: 'Antarctica/South_Pole',
          },
          {
            value: 'Antarctica/Syowa',
            label: 'Antarctica/Syowa',
          },
          {
            value: 'Antarctica/Troll',
            label: 'Antarctica/Troll',
          },
          {
            value: 'Antarctica/Vostok',
            label: 'Antarctica/Vostok',
          },
          {
            value: 'Arctic/Longyearbyen',
            label: 'Arctic/Longyearbyen',
          },
          {
            value: 'Asia/Aden',
            label: 'Asia/Aden',
          },
          {
            value: 'Asia/Almaty',
            label: 'Asia/Almaty',
          },
          {
            value: 'Asia/Amman',
            label: 'Asia/Amman',
          },
          {
            value: 'Asia/Anadyr',
            label: 'Asia/Anadyr',
          },
          {
            value: 'Asia/Aqtau',
            label: 'Asia/Aqtau',
          },
          {
            value: 'Asia/Aqtobe',
            label: 'Asia/Aqtobe',
          },
          {
            value: 'Asia/Ashgabat',
            label: 'Asia/Ashgabat',
          },
          {
            value: 'Asia/Ashkhabad',
            label: 'Asia/Ashkhabad',
          },
          {
            value: 'Asia/Atyrau',
            label: 'Asia/Atyrau',
          },
          {
            value: 'Asia/Baghdad',
            label: 'Asia/Baghdad',
          },
          {
            value: 'Asia/Bahrain',
            label: 'Asia/Bahrain',
          },
          {
            value: 'Asia/Baku',
            label: 'Asia/Baku',
          },
          {
            value: 'Asia/Bangkok',
            label: 'Asia/Bangkok',
          },
          {
            value: 'Asia/Barnaul',
            label: 'Asia/Barnaul',
          },
          {
            value: 'Asia/Beirut',
            label: 'Asia/Beirut',
          },
          {
            value: 'Asia/Bishkek',
            label: 'Asia/Bishkek',
          },
          {
            value: 'Asia/Brunei',
            label: 'Asia/Brunei',
          },
          {
            value: 'Asia/Calcutta',
            label: 'Asia/Calcutta',
          },
          {
            value: 'Asia/Chita',
            label: 'Asia/Chita',
          },
          {
            value: 'Asia/Choibalsan',
            label: 'Asia/Choibalsan',
          },
          {
            value: 'Asia/Chongqing',
            label: 'Asia/Chongqing',
          },
          {
            value: 'Asia/Chungking',
            label: 'Asia/Chungking',
          },
          {
            value: 'Asia/Colombo',
            label: 'Asia/Colombo',
          },
          {
            value: 'Asia/Dacca',
            label: 'Asia/Dacca',
          },
          {
            value: 'Asia/Damascus',
            label: 'Asia/Damascus',
          },
          {
            value: 'Asia/Dhaka',
            label: 'Asia/Dhaka',
          },
          {
            value: 'Asia/Dili',
            label: 'Asia/Dili',
          },
          {
            value: 'Asia/Dubai',
            label: 'Asia/Dubai',
          },
          {
            value: 'Asia/Dushanbe',
            label: 'Asia/Dushanbe',
          },
          {
            value: 'Asia/Famagusta',
            label: 'Asia/Famagusta',
          },
          {
            value: 'Asia/Gaza',
            label: 'Asia/Gaza',
          },
          {
            value: 'Asia/Harbin',
            label: 'Asia/Harbin',
          },
          {
            value: 'Asia/Hebron',
            label: 'Asia/Hebron',
          },
          {
            value: 'Asia/Ho_Chi_Minh',
            label: 'Asia/Ho_Chi_Minh',
          },
          {
            value: 'Asia/Hong_Kong',
            label: 'Asia/Hong_Kong',
          },
          {
            value: 'Asia/Hovd',
            label: 'Asia/Hovd',
          },
          {
            value: 'Asia/Irkutsk',
            label: 'Asia/Irkutsk',
          },
          {
            value: 'Asia/Istanbul',
            label: 'Asia/Istanbul',
          },
          {
            value: 'Asia/Jakarta',
            label: 'Asia/Jakarta',
          },
          {
            value: 'Asia/Jayapura',
            label: 'Asia/Jayapura',
          },
          {
            value: 'Asia/Jerusalem',
            label: 'Asia/Jerusalem',
          },
          {
            value: 'Asia/Kabul',
            label: 'Asia/Kabul',
          },
          {
            value: 'Asia/Kamchatka',
            label: 'Asia/Kamchatka',
          },
          {
            value: 'Asia/Karachi',
            label: 'Asia/Karachi',
          },
          {
            value: 'Asia/Kashgar',
            label: 'Asia/Kashgar',
          },
          {
            value: 'Asia/Kathmandu',
            label: 'Asia/Kathmandu',
          },
          {
            value: 'Asia/Katmandu',
            label: 'Asia/Katmandu',
          },
          {
            value: 'Asia/Khandyga',
            label: 'Asia/Khandyga',
          },
          {
            value: 'Asia/Kolkata',
            label: 'Asia/Kolkata',
          },
          {
            value: 'Asia/Krasnoyarsk',
            label: 'Asia/Krasnoyarsk',
          },
          {
            value: 'Asia/Kuala_Lumpur',
            label: 'Asia/Kuala_Lumpur',
          },
          {
            value: 'Asia/Kuching',
            label: 'Asia/Kuching',
          },
          {
            value: 'Asia/Kuwait',
            label: 'Asia/Kuwait',
          },
          {
            value: 'Asia/Macao',
            label: 'Asia/Macao',
          },
          {
            value: 'Asia/Macau',
            label: 'Asia/Macau',
          },
          {
            value: 'Asia/Magadan',
            label: 'Asia/Magadan',
          },
          {
            value: 'Asia/Makassar',
            label: 'Asia/Makassar',
          },
          {
            value: 'Asia/Manila',
            label: 'Asia/Manila',
          },
          {
            value: 'Asia/Muscat',
            label: 'Asia/Muscat',
          },
          {
            value: 'Asia/Nicosia',
            label: 'Asia/Nicosia',
          },
          {
            value: 'Asia/Novokuznetsk',
            label: 'Asia/Novokuznetsk',
          },
          {
            value: 'Asia/Novosibirsk',
            label: 'Asia/Novosibirsk',
          },
          {
            value: 'Asia/Omsk',
            label: 'Asia/Omsk',
          },
          {
            value: 'Asia/Oral',
            label: 'Asia/Oral',
          },
          {
            value: 'Asia/Phnom_Penh',
            label: 'Asia/Phnom_Penh',
          },
          {
            value: 'Asia/Pontianak',
            label: 'Asia/Pontianak',
          },
          {
            value: 'Asia/Pyongyang',
            label: 'Asia/Pyongyang',
          },
          {
            value: 'Asia/Qatar',
            label: 'Asia/Qatar',
          },
          {
            value: 'Asia/Qostanay',
            label: 'Asia/Qostanay',
          },
          {
            value: 'Asia/Qyzylorda',
            label: 'Asia/Qyzylorda',
          },
          {
            value: 'Asia/Rangoon',
            label: 'Asia/Rangoon',
          },
          {
            value: 'Asia/Riyadh',
            label: 'Asia/Riyadh',
          },
          {
            value: 'Asia/Saigon',
            label: 'Asia/Saigon',
          },
          {
            value: 'Asia/Sakhalin',
            label: 'Asia/Sakhalin',
          },
          {
            value: 'Asia/Samarkand',
            label: 'Asia/Samarkand',
          },
          {
            value: 'Asia/Seoul',
            label: 'Asia/Seoul',
          },
          {
            value: 'Asia/Shanghai',
            label: 'Asia/Shanghai',
          },
          {
            value: 'Asia/Singapore',
            label: 'Asia/Singapore',
          },
          {
            value: 'Asia/Srednekolymsk',
            label: 'Asia/Srednekolymsk',
          },
          {
            value: 'Asia/Taipei',
            label: 'Asia/Taipei',
          },
          {
            value: 'Asia/Tashkent',
            label: 'Asia/Tashkent',
          },
          {
            value: 'Asia/Tbilisi',
            label: 'Asia/Tbilisi',
          },
          {
            value: 'Asia/Tehran',
            label: 'Asia/Tehran',
          },
          {
            value: 'Asia/Tel_Aviv',
            label: 'Asia/Tel_Aviv',
          },
          {
            value: 'Asia/Thimbu',
            label: 'Asia/Thimbu',
          },
          {
            value: 'Asia/Thimphu',
            label: 'Asia/Thimphu',
          },
          {
            value: 'Asia/Tokyo',
            label: 'Asia/Tokyo',
          },
          {
            value: 'Asia/Tomsk',
            label: 'Asia/Tomsk',
          },
          {
            value: 'Asia/Ujung_Pandang',
            label: 'Asia/Ujung_Pandang',
          },
          {
            value: 'Asia/Ulaanbaatar',
            label: 'Asia/Ulaanbaatar',
          },
          {
            value: 'Asia/Ulan_Bator',
            label: 'Asia/Ulan_Bator',
          },
          {
            value: 'Asia/Urumqi',
            label: 'Asia/Urumqi',
          },
          {
            value: 'Asia/Ust-Nera',
            label: 'Asia/Ust-Nera',
          },
          {
            value: 'Asia/Vientiane',
            label: 'Asia/Vientiane',
          },
          {
            value: 'Asia/Vladivostok',
            label: 'Asia/Vladivostok',
          },
          {
            value: 'Asia/Yakutsk',
            label: 'Asia/Yakutsk',
          },
          {
            value: 'Asia/Yangon',
            label: 'Asia/Yangon',
          },
          {
            value: 'Asia/Yekaterinburg',
            label: 'Asia/Yekaterinburg',
          },
          {
            value: 'Asia/Yerevan',
            label: 'Asia/Yerevan',
          },
          {
            value: 'Atlantic/Azores',
            label: 'Atlantic/Azores',
          },
          {
            value: 'Atlantic/Bermuda',
            label: 'Atlantic/Bermuda',
          },
          {
            value: 'Atlantic/Canary',
            label: 'Atlantic/Canary',
          },
          {
            value: 'Atlantic/Cape_Verde',
            label: 'Atlantic/Cape_Verde',
          },
          {
            value: 'Atlantic/Faeroe',
            label: 'Atlantic/Faeroe',
          },
          {
            value: 'Atlantic/Faroe',
            label: 'Atlantic/Faroe',
          },
          {
            value: 'Atlantic/Jan_Mayen',
            label: 'Atlantic/Jan_Mayen',
          },
          {
            value: 'Atlantic/Madeira',
            label: 'Atlantic/Madeira',
          },
          {
            value: 'Atlantic/Reykjavik',
            label: 'Atlantic/Reykjavik',
          },
          {
            value: 'Atlantic/South_Georgia',
            label: 'Atlantic/South_Georgia',
          },
          {
            value: 'Atlantic/St_Helena',
            label: 'Atlantic/St_Helena',
          },
          {
            value: 'Atlantic/Stanley',
            label: 'Atlantic/Stanley',
          },
          {
            value: 'Australia/ACT',
            label: 'Australia/ACT',
          },
          {
            value: 'Australia/Adelaide',
            label: 'Australia/Adelaide',
          },
          {
            value: 'Australia/Brisbane',
            label: 'Australia/Brisbane',
          },
          {
            value: 'Australia/Broken_Hill',
            label: 'Australia/Broken_Hill',
          },
          {
            value: 'Australia/Canberra',
            label: 'Australia/Canberra',
          },
          {
            value: 'Australia/Currie',
            label: 'Australia/Currie',
          },
          {
            value: 'Australia/Darwin',
            label: 'Australia/Darwin',
          },
          {
            value: 'Australia/Eucla',
            label: 'Australia/Eucla',
          },
          {
            value: 'Australia/Hobart',
            label: 'Australia/Hobart',
          },
          {
            value: 'Australia/LHI',
            label: 'Australia/LHI',
          },
          {
            value: 'Australia/Lindeman',
            label: 'Australia/Lindeman',
          },
          {
            value: 'Australia/Lord_Howe',
            label: 'Australia/Lord_Howe',
          },
          {
            value: 'Australia/Melbourne',
            label: 'Australia/Melbourne',
          },
          {
            value: 'Australia/NSW',
            label: 'Australia/NSW',
          },
          {
            value: 'Australia/North',
            label: 'Australia/North',
          },
          {
            value: 'Australia/Perth',
            label: 'Australia/Perth',
          },
          {
            value: 'Australia/Queensland',
            label: 'Australia/Queensland',
          },
          {
            value: 'Australia/South',
            label: 'Australia/South',
          },
          {
            value: 'Australia/Sydney',
            label: 'Australia/Sydney',
          },
          {
            value: 'Australia/Tasmania',
            label: 'Australia/Tasmania',
          },
          {
            value: 'Australia/Victoria',
            label: 'Australia/Victoria',
          },
          {
            value: 'Australia/West',
            label: 'Australia/West',
          },
          {
            value: 'Australia/Yancowinna',
            label: 'Australia/Yancowinna',
          },
          {
            value: 'Brazil/Acre',
            label: 'Brazil/Acre',
          },
          {
            value: 'Brazil/DeNoronha',
            label: 'Brazil/DeNoronha',
          },
          {
            value: 'Brazil/East',
            label: 'Brazil/East',
          },
          {
            value: 'Brazil/West',
            label: 'Brazil/West',
          },
          {
            value: 'CET',
            label: 'CET',
          },
          {
            value: 'CST6CDT',
            label: 'CST6CDT',
          },
          {
            value: 'Canada/Atlantic',
            label: 'Canada/Atlantic',
          },
          {
            value: 'Canada/Central',
            label: 'Canada/Central',
          },
          {
            value: 'Canada/Eastern',
            label: 'Canada/Eastern',
          },
          {
            value: 'Canada/Mountain',
            label: 'Canada/Mountain',
          },
          {
            value: 'Canada/Newfoundland',
            label: 'Canada/Newfoundland',
          },
          {
            value: 'Canada/Pacific',
            label: 'Canada/Pacific',
          },
          {
            value: 'Canada/Saskatchewan',
            label: 'Canada/Saskatchewan',
          },
          {
            value: 'Canada/Yukon',
            label: 'Canada/Yukon',
          },
          {
            value: 'Chile/Continental',
            label: 'Chile/Continental',
          },
          {
            value: 'Chile/EasterIsland',
            label: 'Chile/EasterIsland',
          },
          {
            value: 'Cuba',
            label: 'Cuba',
          },
          {
            value: 'EET',
            label: 'EET',
          },
          {
            value: 'EST',
            label: 'EST',
          },
          {
            value: 'EST5EDT',
            label: 'EST5EDT',
          },
          {
            value: 'Egypt',
            label: 'Egypt',
          },
          {
            value: 'Eire',
            label: 'Eire',
          },
          {
            value: 'Etc/GMT',
            label: 'Etc/GMT',
          },
          {
            value: 'Etc/GMT+0',
            label: 'Etc/GMT+0',
          },
          {
            value: 'Etc/GMT+1',
            label: 'Etc/GMT+1',
          },
          {
            value: 'Etc/GMT+10',
            label: 'Etc/GMT+10',
          },
          {
            value: 'Etc/GMT+11',
            label: 'Etc/GMT+11',
          },
          {
            value: 'Etc/GMT+12',
            label: 'Etc/GMT+12',
          },
          {
            value: 'Etc/GMT+2',
            label: 'Etc/GMT+2',
          },
          {
            value: 'Etc/GMT+3',
            label: 'Etc/GMT+3',
          },
          {
            value: 'Etc/GMT+4',
            label: 'Etc/GMT+4',
          },
          {
            value: 'Etc/GMT+5',
            label: 'Etc/GMT+5',
          },
          {
            value: 'Etc/GMT+6',
            label: 'Etc/GMT+6',
          },
          {
            value: 'Etc/GMT+7',
            label: 'Etc/GMT+7',
          },
          {
            value: 'Etc/GMT+8',
            label: 'Etc/GMT+8',
          },
          {
            value: 'Etc/GMT+9',
            label: 'Etc/GMT+9',
          },
          {
            value: 'Etc/GMT-0',
            label: 'Etc/GMT-0',
          },
          {
            value: 'Etc/GMT-1',
            label: 'Etc/GMT-1',
          },
          {
            value: 'Etc/GMT-10',
            label: 'Etc/GMT-10',
          },
          {
            value: 'Etc/GMT-11',
            label: 'Etc/GMT-11',
          },
          {
            value: 'Etc/GMT-12',
            label: 'Etc/GMT-12',
          },
          {
            value: 'Etc/GMT-13',
            label: 'Etc/GMT-13',
          },
          {
            value: 'Etc/GMT-14',
            label: 'Etc/GMT-14',
          },
          {
            value: 'Etc/GMT-2',
            label: 'Etc/GMT-2',
          },
          {
            value: 'Etc/GMT-3',
            label: 'Etc/GMT-3',
          },
          {
            value: 'Etc/GMT-4',
            label: 'Etc/GMT-4',
          },
          {
            value: 'Etc/GMT-5',
            label: 'Etc/GMT-5',
          },
          {
            value: 'Etc/GMT-6',
            label: 'Etc/GMT-6',
          },
          {
            value: 'Etc/GMT-7',
            label: 'Etc/GMT-7',
          },
          {
            value: 'Etc/GMT-8',
            label: 'Etc/GMT-8',
          },
          {
            value: 'Etc/GMT-9',
            label: 'Etc/GMT-9',
          },
          {
            value: 'Etc/GMT0',
            label: 'Etc/GMT0',
          },
          {
            value: 'Etc/Greenwich',
            label: 'Etc/Greenwich',
          },
          {
            value: 'Etc/UCT',
            label: 'Etc/UCT',
          },
          {
            value: 'Etc/UTC',
            label: 'Etc/UTC',
          },
          {
            value: 'Etc/Universal',
            label: 'Etc/Universal',
          },
          {
            value: 'Etc/Zulu',
            label: 'Etc/Zulu',
          },
          {
            value: 'Europe/Amsterdam',
            label: 'Europe/Amsterdam',
          },
          {
            value: 'Europe/Andorra',
            label: 'Europe/Andorra',
          },
          {
            value: 'Europe/Astrakhan',
            label: 'Europe/Astrakhan',
          },
          {
            value: 'Europe/Athens',
            label: 'Europe/Athens',
          },
          {
            value: 'Europe/Belfast',
            label: 'Europe/Belfast',
          },
          {
            value: 'Europe/Belgrade',
            label: 'Europe/Belgrade',
          },
          {
            value: 'Europe/Berlin',
            label: 'Europe/Berlin',
          },
          {
            value: 'Europe/Bratislava',
            label: 'Europe/Bratislava',
          },
          {
            value: 'Europe/Brussels',
            label: 'Europe/Brussels',
          },
          {
            value: 'Europe/Bucharest',
            label: 'Europe/Bucharest',
          },
          {
            value: 'Europe/Budapest',
            label: 'Europe/Budapest',
          },
          {
            value: 'Europe/Busingen',
            label: 'Europe/Busingen',
          },
          {
            value: 'Europe/Chisinau',
            label: 'Europe/Chisinau',
          },
          {
            value: 'Europe/Copenhagen',
            label: 'Europe/Copenhagen',
          },
          {
            value: 'Europe/Dublin',
            label: 'Europe/Dublin',
          },
          {
            value: 'Europe/Gibraltar',
            label: 'Europe/Gibraltar',
          },
          {
            value: 'Europe/Guernsey',
            label: 'Europe/Guernsey',
          },
          {
            value: 'Europe/Helsinki',
            label: 'Europe/Helsinki',
          },
          {
            value: 'Europe/Isle_of_Man',
            label: 'Europe/Isle_of_Man',
          },
          {
            value: 'Europe/Istanbul',
            label: 'Europe/Istanbul',
          },
          {
            value: 'Europe/Jersey',
            label: 'Europe/Jersey',
          },
          {
            value: 'Europe/Kaliningrad',
            label: 'Europe/Kaliningrad',
          },
          {
            value: 'Europe/Kiev',
            label: 'Europe/Kiev',
          },
          {
            value: 'Europe/Kirov',
            label: 'Europe/Kirov',
          },
          {
            value: 'Europe/Lisbon',
            label: 'Europe/Lisbon',
          },
          {
            value: 'Europe/Ljubljana',
            label: 'Europe/Ljubljana',
          },
          {
            value: 'Europe/London',
            label: 'Europe/London',
          },
          {
            value: 'Europe/Luxembourg',
            label: 'Europe/Luxembourg',
          },
          {
            value: 'Europe/Madrid',
            label: 'Europe/Madrid',
          },
          {
            value: 'Europe/Malta',
            label: 'Europe/Malta',
          },
          {
            value: 'Europe/Mariehamn',
            label: 'Europe/Mariehamn',
          },
          {
            value: 'Europe/Minsk',
            label: 'Europe/Minsk',
          },
          {
            value: 'Europe/Monaco',
            label: 'Europe/Monaco',
          },
          {
            value: 'Europe/Moscow',
            label: 'Europe/Moscow',
          },
          {
            value: 'Europe/Nicosia',
            label: 'Europe/Nicosia',
          },
          {
            value: 'Europe/Oslo',
            label: 'Europe/Oslo',
          },
          {
            value: 'Europe/Paris',
            label: 'Europe/Paris',
          },
          {
            value: 'Europe/Podgorica',
            label: 'Europe/Podgorica',
          },
          {
            value: 'Europe/Prague',
            label: 'Europe/Prague',
          },
          {
            value: 'Europe/Riga',
            label: 'Europe/Riga',
          },
          {
            value: 'Europe/Rome',
            label: 'Europe/Rome',
          },
          {
            value: 'Europe/Samara',
            label: 'Europe/Samara',
          },
          {
            value: 'Europe/San_Marino',
            label: 'Europe/San_Marino',
          },
          {
            value: 'Europe/Sarajevo',
            label: 'Europe/Sarajevo',
          },
          {
            value: 'Europe/Saratov',
            label: 'Europe/Saratov',
          },
          {
            value: 'Europe/Simferopol',
            label: 'Europe/Simferopol',
          },
          {
            value: 'Europe/Skopje',
            label: 'Europe/Skopje',
          },
          {
            value: 'Europe/Sofia',
            label: 'Europe/Sofia',
          },
          {
            value: 'Europe/Stockholm',
            label: 'Europe/Stockholm',
          },
          {
            value: 'Europe/Tallinn',
            label: 'Europe/Tallinn',
          },
          {
            value: 'Europe/Tirane',
            label: 'Europe/Tirane',
          },
          {
            value: 'Europe/Tiraspol',
            label: 'Europe/Tiraspol',
          },
          {
            value: 'Europe/Ulyanovsk',
            label: 'Europe/Ulyanovsk',
          },
          {
            value: 'Europe/Uzhgorod',
            label: 'Europe/Uzhgorod',
          },
          {
            value: 'Europe/Vaduz',
            label: 'Europe/Vaduz',
          },
          {
            value: 'Europe/Vatican',
            label: 'Europe/Vatican',
          },
          {
            value: 'Europe/Vienna',
            label: 'Europe/Vienna',
          },
          {
            value: 'Europe/Vilnius',
            label: 'Europe/Vilnius',
          },
          {
            value: 'Europe/Volgograd',
            label: 'Europe/Volgograd',
          },
          {
            value: 'Europe/Warsaw',
            label: 'Europe/Warsaw',
          },
          {
            value: 'Europe/Zagreb',
            label: 'Europe/Zagreb',
          },
          {
            value: 'Europe/Zaporozhye',
            label: 'Europe/Zaporozhye',
          },
          {
            value: 'Europe/Zurich',
            label: 'Europe/Zurich',
          },
          {
            value: 'GB',
            label: 'GB',
          },
          {
            value: 'GB-Eire',
            label: 'GB-Eire',
          },
          {
            value: 'GMT',
            label: 'GMT',
          },
          {
            value: 'GMT+0',
            label: 'GMT+0',
          },
          {
            value: 'GMT-0',
            label: 'GMT-0',
          },
          {
            value: 'GMT0',
            label: 'GMT0',
          },
          {
            value: 'Greenwich',
            label: 'Greenwich',
          },
          {
            value: 'HST',
            label: 'HST',
          },
          {
            value: 'Hongkong',
            label: 'Hongkong',
          },
          {
            value: 'Iceland',
            label: 'Iceland',
          },
          {
            value: 'Indian/Antananarivo',
            label: 'Indian/Antananarivo',
          },
          {
            value: 'Indian/Chagos',
            label: 'Indian/Chagos',
          },
          {
            value: 'Indian/Christmas',
            label: 'Indian/Christmas',
          },
          {
            value: 'Indian/Cocos',
            label: 'Indian/Cocos',
          },
          {
            value: 'Indian/Comoro',
            label: 'Indian/Comoro',
          },
          {
            value: 'Indian/Kerguelen',
            label: 'Indian/Kerguelen',
          },
          {
            value: 'Indian/Mahe',
            label: 'Indian/Mahe',
          },
          {
            value: 'Indian/Maldives',
            label: 'Indian/Maldives',
          },
          {
            value: 'Indian/Mauritius',
            label: 'Indian/Mauritius',
          },
          {
            value: 'Indian/Mayotte',
            label: 'Indian/Mayotte',
          },
          {
            value: 'Indian/Reunion',
            label: 'Indian/Reunion',
          },
          {
            value: 'Iran',
            label: 'Iran',
          },
          {
            value: 'Israel',
            label: 'Israel',
          },
          {
            value: 'Jamaica',
            label: 'Jamaica',
          },
          {
            value: 'Japan',
            label: 'Japan',
          },
          {
            value: 'Kwajalein',
            label: 'Kwajalein',
          },
          {
            value: 'Libya',
            label: 'Libya',
          },
          {
            value: 'MET',
            label: 'MET',
          },
          {
            value: 'MST',
            label: 'MST',
          },
          {
            value: 'MST7MDT',
            label: 'MST7MDT',
          },
          {
            value: 'Mexico/BajaNorte',
            label: 'Mexico/BajaNorte',
          },
          {
            value: 'Mexico/BajaSur',
            label: 'Mexico/BajaSur',
          },
          {
            value: 'Mexico/General',
            label: 'Mexico/General',
          },
          {
            value: 'NZ',
            label: 'NZ',
          },
          {
            value: 'NZ-CHAT',
            label: 'NZ-CHAT',
          },
          {
            value: 'Navajo',
            label: 'Navajo',
          },
          {
            value: 'PRC',
            label: 'PRC',
          },
          {
            value: 'PST8PDT',
            label: 'PST8PDT',
          },
          {
            value: 'Pacific/Apia',
            label: 'Pacific/Apia',
          },
          {
            value: 'Pacific/Auckland',
            label: 'Pacific/Auckland',
          },
          {
            value: 'Pacific/Bougainville',
            label: 'Pacific/Bougainville',
          },
          {
            value: 'Pacific/Chatham',
            label: 'Pacific/Chatham',
          },
          {
            value: 'Pacific/Chuuk',
            label: 'Pacific/Chuuk',
          },
          {
            value: 'Pacific/Easter',
            label: 'Pacific/Easter',
          },
          {
            value: 'Pacific/Efate',
            label: 'Pacific/Efate',
          },
          {
            value: 'Pacific/Enderbury',
            label: 'Pacific/Enderbury',
          },
          {
            value: 'Pacific/Fakaofo',
            label: 'Pacific/Fakaofo',
          },
          {
            value: 'Pacific/Fiji',
            label: 'Pacific/Fiji',
          },
          {
            value: 'Pacific/Funafuti',
            label: 'Pacific/Funafuti',
          },
          {
            value: 'Pacific/Galapagos',
            label: 'Pacific/Galapagos',
          },
          {
            value: 'Pacific/Gambier',
            label: 'Pacific/Gambier',
          },
          {
            value: 'Pacific/Guadalcanal',
            label: 'Pacific/Guadalcanal',
          },
          {
            value: 'Pacific/Guam',
            label: 'Pacific/Guam',
          },
          {
            value: 'Pacific/Honolulu',
            label: 'Pacific/Honolulu',
          },
          {
            value: 'Pacific/Johnston',
            label: 'Pacific/Johnston',
          },
          {
            value: 'Pacific/Kanton',
            label: 'Pacific/Kanton',
          },
          {
            value: 'Pacific/Kiritimati',
            label: 'Pacific/Kiritimati',
          },
          {
            value: 'Pacific/Kosrae',
            label: 'Pacific/Kosrae',
          },
          {
            value: 'Pacific/Kwajalein',
            label: 'Pacific/Kwajalein',
          },
          {
            value: 'Pacific/Majuro',
            label: 'Pacific/Majuro',
          },
          {
            value: 'Pacific/Marquesas',
            label: 'Pacific/Marquesas',
          },
          {
            value: 'Pacific/Midway',
            label: 'Pacific/Midway',
          },
          {
            value: 'Pacific/Nauru',
            label: 'Pacific/Nauru',
          },
          {
            value: 'Pacific/Niue',
            label: 'Pacific/Niue',
          },
          {
            value: 'Pacific/Norfolk',
            label: 'Pacific/Norfolk',
          },
          {
            value: 'Pacific/Noumea',
            label: 'Pacific/Noumea',
          },
          {
            value: 'Pacific/Pago_Pago',
            label: 'Pacific/Pago_Pago',
          },
          {
            value: 'Pacific/Palau',
            label: 'Pacific/Palau',
          },
          {
            value: 'Pacific/Pitcairn',
            label: 'Pacific/Pitcairn',
          },
          {
            value: 'Pacific/Pohnpei',
            label: 'Pacific/Pohnpei',
          },
          {
            value: 'Pacific/Ponape',
            label: 'Pacific/Ponape',
          },
          {
            value: 'Pacific/Port_Moresby',
            label: 'Pacific/Port_Moresby',
          },
          {
            value: 'Pacific/Rarotonga',
            label: 'Pacific/Rarotonga',
          },
          {
            value: 'Pacific/Saipan',
            label: 'Pacific/Saipan',
          },
          {
            value: 'Pacific/Samoa',
            label: 'Pacific/Samoa',
          },
          {
            value: 'Pacific/Tahiti',
            label: 'Pacific/Tahiti',
          },
          {
            value: 'Pacific/Tarawa',
            label: 'Pacific/Tarawa',
          },
          {
            value: 'Pacific/Tongatapu',
            label: 'Pacific/Tongatapu',
          },
          {
            value: 'Pacific/Truk',
            label: 'Pacific/Truk',
          },
          {
            value: 'Pacific/Wake',
            label: 'Pacific/Wake',
          },
          {
            value: 'Pacific/Wallis',
            label: 'Pacific/Wallis',
          },
          {
            value: 'Pacific/Yap',
            label: 'Pacific/Yap',
          },
          {
            value: 'Poland',
            label: 'Poland',
          },
          {
            value: 'Portugal',
            label: 'Portugal',
          },
          {
            value: 'ROC',
            label: 'ROC',
          },
          {
            value: 'ROK',
            label: 'ROK',
          },
          {
            value: 'Singapore',
            label: 'Singapore',
          },
          {
            value: 'Turkey',
            label: 'Turkey',
          },
          {
            value: 'UCT',
            label: 'UCT',
          },
          {
            value: 'US/Alaska',
            label: 'US/Alaska',
          },
          {
            value: 'US/Aleutian',
            label: 'US/Aleutian',
          },
          {
            value: 'US/Arizona',
            label: 'US/Arizona',
          },
          {
            value: 'US/Central',
            label: 'US/Central',
          },
          {
            value: 'US/East-Indiana',
            label: 'US/East-Indiana',
          },
          {
            value: 'US/Eastern',
            label: 'US/Eastern',
          },
          {
            value: 'US/Hawaii',
            label: 'US/Hawaii',
          },
          {
            value: 'US/Indiana-Starke',
            label: 'US/Indiana-Starke',
          },
          {
            value: 'US/Michigan',
            label: 'US/Michigan',
          },
          {
            value: 'US/Mountain',
            label: 'US/Mountain',
          },
          {
            value: 'US/Pacific',
            label: 'US/Pacific',
          },
          {
            value: 'US/Samoa',
            label: 'US/Samoa',
          },
          {
            value: 'UTC',
            label: 'UTC',
          },
          {
            value: 'Universal',
            label: 'Universal',
          },
          {
            value: 'W-SU',
            label: 'W-SU',
          },
          {
            value: 'WET',
            label: 'WET',
          },
          {
            value: 'Zulu',
            label: 'Zulu',
          },
        ],
        defaultValue: '',
        withtooltip: false,
        tooltip: '',
      },
    ],
  },
  api: {
    name: 'api',
    default: {
      limit: 20,
      sql_limit: 200,
    },
    fields: [
      {
        fieldName: 'acceptTerms',
        fieldText: (
          <p>
            You are responsible for respecting API limits and other requirements from third party
            APIs(use the wait and stop condition below).Violation can result in blacklisting from
            third party APIs.See more in our{' '}
            <a
              className="border-b-2 border-transparent hover:border-[#5DA6FC]"
              target="_newtab"
              href="https://www.less.tech/terms-of-use/"
            >
              Terms of Use{' '}
            </a>
            .{' '}
          </p>
        ),
        placeholder: '',
        fieldType: 'radio',
        optionType: 'manual',
        options: [
          {
            value: 'I understand',
            label: 'Accept',
          },
        ],
      },
      {
        fieldName: 'requestType',
        fieldText: '#1 Select the API type you want to use',
        fieldType: 'radio',
        optionType: 'manual',
        options: [
          {
            value: 'GET',
            label: 'GET',
          },
          {
            value: 'POST',
            label: 'POST',
          },
          {
            value: 'PUT',
            label: 'PUT',
          },
          {
            value: 'PATCH',
            label: 'PATCH',
          },
          {
            value: 'DELETE',
            label: 'DELETE',
          },
        ],
      },
      {
        fieldName: 'baseURL',
        fieldText: '#2 Input your API URL',
        fieldType: 'combobox',
        placeholder: 'https://api.my-ip.io/ip',
        defaultValue: '',
        size: 'small',
        withtooltip: true,
        tooltip:
          'To use columns in your dataset to construct the URL, simply begin typing the column name you want to use',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'headers',
        fieldText: '#3 Add key-value pairs for your call headers',
        fieldType: 'KeyValueField',
        placeholder: 'None',
        actionLabel: 'Header',
        secondHeader: 'Value',
        optionType: 'columns',
        options: 'dataframe',
        withtooltip: true,
        tooltip:
          'To use columns in your dataset to construct the headers, simply begin typing the column name you want to use',
        optional: true,
        defaultValue: [
          {
            left: '',
            right: '',
            label: 'KeyValuePair',
          },
        ],
        buttonText: 'Add header',
      },
      {
        fieldName: 'parameters',
        fieldText: '#4 Add key-value pairs for your call parameters',
        fieldType: 'KeyValueField',
        placeholder: 'None',
        actionLabel: 'Parameter',
        secondHeader: 'Value',
        optional: true,
        optionType: 'columns',
        options: 'dataframe',
        withtooltip: true,
        tooltip:
          'To use columns in your dataset to construct the parameters, simply begin typing the column name you want to use',
        defaultValue: [
          {
            left: '',
            right: '',
            label: 'KeyValuePair',
          },
        ],
        buttonText: 'Add parameter',
      },
      // {
      //   fieldName: 'sampleURL',
      //   fieldText: '#5 View sample URL',
      //   placeholder: 'https://api.my-ip.io/ip',
      //   fieldType: 'text',
      //   disabled: true,
      // },
      {
        fieldName: 'body',
        fieldText: '#5 Add JSON body for request (only relevant for POST and PUT calls)',
        fieldType: 'combobox',
        placeholder: 'https://api.my-ip.io/ip',
        defaultValue: '',
        size: 'small',
        withtooltip: true,
        optional: true,
        tooltip:
          'To use columns in your dataset to construct the body, simply begin typing the column name you want to use',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'readBodyJson',
        fieldText: 'Treat body as JSON object?',
        placeholder: 'Click yes if you would like to convert the JSON to key:value pairs',
        fieldType: 'radio',
        defaultValue: '',
        optionType: 'manual',
        options: [
          {
            value: 'true',
            label: 'Yes - convert to key:value pairs',
          },
          {
            value: 'false',
            label: 'No - treat it as a JSON body',
          },
        ],
      },
      {
        fieldName: 'waitConditions',
        fieldText: '#6 Add wait conditions',
        fieldType: 'checkbox',
        defaultValue: {},
        withtooltip: true,
        tooltip: 'Helpful for ensuring compliance with third party API limits',
        optional: true,
        optionType: 'manual',
        options: [
          {
            textBefore: '',
            name: 'calls',
            textAfter: 'second(s) between each call',
            inputPlaceholder: '10',
            //max: 3,
          },
          {
            textBefore: 'Max',
            name: 'perMinute',
            textAfter: 'call(s) per minute',
            inputPlaceholder: '60',
            //max: 3,
          },
        ],
      },
      {
        fieldName: 'stopConditions',
        fieldText: '#7 Add stop conditions',
        fieldType: 'checkbox',
        placeholder: 'https://api.my-ip.io/ip',
        defaultValue: {},
        withtooltip: true,
        optional: true,
        tooltip: 'We highly recommend using stop conditions during your testing phase',
        optionType: 'manual',
        options: [
          {
            textBefore: 'After',
            name: 'calls',
            textAfter: 'call(s)',
            inputPlaceholder: '1',
            //max: 100000,
          },
          {
            textBefore: 'After',
            name: 'minutes',
            textAfter: 'minute(s)',
            inputPlaceholder: '2',
            //max: 120,
          },
        ],
      },
    ],
  },
  holidays: {
    name: 'holidays',
    fields: [
      {
        fieldName: 'description',
        fieldText:
          'The "D" input should contain your regular data and the "H" input should contain your holiday data',
        fieldType: 'description',
      },
      {
        fieldName: 'columnName',
        fieldText: '#1 Name your new column',
        placeholder: 'Holiday_End_date',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'columnStartDate',
        fieldText: '#2 Select the column with your start date (from the "D" input)',
        placeholder: 'Column',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'df_l',
      },
      {
        fieldName: 'columnDuration',
        fieldText:
          '#3 Select the column with the number of business days you would like to add - i.e. exlcuding weekends and holidays (from the "D" input)',
        placeholder: 'Column',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'df_l',
      },
      {
        fieldName: 'columnNameHoliday',
        fieldText: '#4 Select the column with the holiday dates (from the "H" input)',
        placeholder: 'Column',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'df_r',
      },
      {
        fieldName: 'description',
        fieldText:
          'If you want to group your holidays and data by a column, you can use this configuration. This is common for filtering for countries with different holidays.',
        fieldType: 'description',
        advanced: true,
      },
      {
        fieldName: 'joinColumns',
        fieldText: 'Select the column identifying the group from each dataset',
        fieldType: 'twoRowGroup',
        placeholder: 'None',
        actionLabel: 'Action',
        leftHeader: 'Data table',
        rightHeader: 'Holiday table',
        secondHeader: 'Add columns to group on',
        btnOn: false,
        optionType: 'columns',
        options: 'df_l',
        defaultValue: [
          {
            left: '',
            right: '',
            label: '',
          },
        ],
        buttonText: 'Add additional columns to group on',
        rightOptionType: 'columns',
        rightOptions: 'df_r',
        advanced: true,
      },
    ],
  },
  changeColumns: {
    name: 'changeColumns',
    fields: [
      {
        fieldName: 'columnNames',
        fieldText: '#1 Select the columns you want to keep',
        fieldType: 'multiSelectSearch2',
        unknownColums: true,
        defaultValue: {},
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  stringtodate: {
    name: 'stringtodate',
    fields: [
      {
        fieldName: 'newName',
        fieldText: '#1 Name your new column',
        placeholder: 'String_As_Date',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'column',
        fieldText: '#2 Select the date column you want to format',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },

      {
        fieldName: 'curFormat',
        fieldText: '#3 Input the format of your incoming string column',
        fieldType: 'combobox',
        placeholder: '%Y.%m.%d',
        defaultValue: '',
        size: 'small',
        withtooltip: true,
        tooltip: 'You can find more information on formatting in our guides',
        optionType: 'manual',
        columnCombo: false,
        options: [
          {
            value: '%a',
            type: {
              Name: 'Weekday as locale’s abbreviated name: Sat, Sun',
            },
          },
          {
            value: '%A',
            type: {
              Name: 'Weekday as locale’s full name: Saturday, Sunday',
            },
          },
          {
            value: '%w',
            type: {
              Name: 'Weekday as a decimal number where 0 is Sunday',
            },
          },
          {
            value: '%d',
            type: {
              Name: 'Day of the month as a decimal number: 01,02, ..., 31',
            },
          },
          {
            value: '%b',
            type: {
              Name: 'Month as locale’s abbreviated name: Jan, Feb',
            },
          },
          {
            value: '%B',
            type: {
              Name: 'Month as locale’s full name: January, February',
            },
          },
          {
            value: '%m',
            type: {
              Name: 'Month as a zero-padded decimal number: 01,02,...,12',
            },
          },
          {
            value: '%y',
            type: {
              Name: 'Year without century as decimal number: 00,01, ...,99',
            },
          },
          {
            value: '%Y',
            type: {
              Name: 'Year with century as a decimal number: 2011, 2012',
            },
          },
          {
            value: '%H',
            type: {
              Name: 'Hour (24-hour clock) as decimal number: 00,01, ..., 23',
            },
          },
          {
            value: '%I',
            type: {
              Name: 'Hour (12-hour clock) as decimal number: 01, 02, ..., 12',
            },
          },
          {
            value: '%p',
            type: {
              Name: 'Locale’s equivalent of either AM or PM: AM, am, pm, PM',
            },
          },
          {
            value: '%M',
            type: {
              Name: 'Minute as a zero-padded decimal number.: 00, 01, ..., 59',
            },
          },
          {
            value: '%S',
            type: {
              Name: 'Second number (00–60), zero-padded to 2 digits.',
            },
          },
          {
            value: '%f',
            type: {
              Name: 'Microsecond as a 6 digits number: 000000, 000001, ..., 999999',
            },
          },
          {
            value: '%z',
            type: {
              Name: 'UTC offset in the form ±HHMM[SS[.ffffff]]: +0000, -0400, +1030',
            },
          },
          {
            value: '%Z',
            type: {
              Name: 'Time zone name: UTC, GMT',
            },
          },
          {
            value: '%j',
            type: {
              Name: 'Day of the year as a decimal number: 001, 002, ..., 366',
            },
          },
          {
            value: '%U',
            type: {
              Name: 'Week number of the year (Sunday as the first day of the week: 00, 01, ..., 53',
            },
          },
          {
            value: '%W',
            type: {
              Name: 'Week number of the year (Monday as the first day of the week): 00, 01, ..., 53',
            },
          },
          {
            value: '%Z',
            type: {
              Name: 'Time zone name: UTC, GMT',
            },
          },
          {
            value: '%Z',
            type: {
              Name: 'Time zone name: UTC, GMT',
            },
          },
        ],
      },
    ],
  },
  regex: {
    name: 'regex',
    fields: [
      {
        fieldName: 'column',
        fieldText: '#1 Select the column you want to perform regex on',
        fieldType: 'dropdown',
        optionType: 'columns',
        placeholder: 'Column',
        options: 'dataframe',
      },
      {
        fieldName: 'columnName',
        fieldText: '#2 Name your new column',
        placeholder: 'extracted_column',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'regex',
        fieldText: '#3 Input your regex formula',
        placeholder: '(.*)',
        withtooltip: true,
        tooltip:
          'You can only extract one marked group at a time. Remember parentheses around your marked groups. No need for quotes around your regex statement',
        fieldType: 'text',
        size: 'small',
      },
    ],
  },
  holidaysDiff: {
    name: 'holidaysDiff',
    fields: [
      {
        fieldName: 'description',
        fieldText:
          'This function outputs a new column with the number of business days inbetween a start and end date accounting for holidays. The "D" input should contain your regular data and the "H" input should contain your holiday data',
        fieldType: 'description',
      },
      {
        fieldName: 'columnName',
        fieldText: '#1 Name your new column',
        placeholder: 'Diff_BusinessDays',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'start',
        fieldText: '#2 Select the column with your start date (from the "D" input)',
        placeholder: 'Column',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'df_l',
      },
      {
        fieldName: 'end',
        fieldText: '#3 Select the column with your end date (from the "D" input)',
        placeholder: 'Column',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'df_l',
      },
      {
        fieldName: 'holiday',
        fieldText: '#4 Select the column with the holiday dates (from the "H" input)',
        placeholder: 'Column',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'df_r',
      },
    ],
  },
  warningXRows: {
    name: 'warningXRows',
    fields: [
      {
        fieldName: 'description',
        fieldText:
          'You can use the Log Warning action to generate a warning. If your workflow contains an error it will show up in the logs and on the model overview page. ',
        fieldType: 'description',
      },
      {
        fieldName: 'rowCount',
        fieldText: 'Trigger a warning if my incoming dataset has more than to X rows',
        placeholder: 'Number of rows',
        fieldType: 'text',
        size: 'small',
      },
      {
        fieldName: 'customMessage',
        fieldText: 'Input a custom warning message',
        placeholder: 'Oh shoot! Something is messed up',
        fieldType: 'textarea',
        optional: true,
        size: 'small',
        withtooltip: true,
        tooltip:
          'This error message will be in the notification on the Canvas and in the model log',
      },
    ],
  },
  multiRowCalc: {
    name: 'multiRowCalc',
    fields: [
      {
        fieldName: 'fieldName',
        fieldText: '#1 Name the new column you want to create',
        placeholder: 'newColumn',
        fieldType: 'text',
        size: 'small',
      },

      {
        fieldName: 'calculation',
        fieldText: '#2 Enter you multi row calculation',
        fieldType: 'formula',
        size: 'small',
        placeholder: '[ColumnA][-1]*[ColumnB]',
        withtooltip: true,
        tooltip: 'See our documentation for more information on how calculations work',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'rowNotAvailable',
        fieldText:
          '#3 Enter a fallback calculation if the rows you are referencing above are not available',
        fieldType: 'formula',
        size: 'small',
        placeholder: '[ColumnA]*2',
        withtooltip: false,
        optionType: 'columns',
        options: 'dataframe',
      },
    ],
  },
  mapbox: {
    name: 'mapbox',
    fields: [
      {
        fieldName: 'description',
        fieldText: (
          <p>
            You are responsible for respecting the MapBox{' '}
            <a
              className="border-b-2 border-transparent hover:border-[#5DA6FC]"
              target="_newtab"
              href="https://www.mapbox.com/legal/tos"
            >
              Terms of Service{' '}
            </a>
            .{' '}
          </p>
        ),
        fieldType: 'description',
      },
      {
        fieldName: 'input',
        fieldText: '#1 Select the column you want to Geocode',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'country',
        fieldText: '#2 Select the column with a country',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
        withtooltip: true,
        tooltip:
          'The column should be in country codes, e.g. dk for Denmark. You can create an empty column with the Text Column tool if you want to leave this out of the MapBox request.',
      },
      {
        fieldName: 'id',
        fieldText: '#3 Select the column that holds an ID',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'type',
        fieldText:
          '#4 Select the column that holds the Geocode Type (Address, Postcode, Place, etc.)',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'accessToken',
        fieldText: '#5 Input your MapBox API token',
        placeholder: '',
        fieldType: 'text',
      },
      {
        fieldName: 'limit',
        fieldText: '#6 Input a limit for the number of returned records from MapBox',
        placeholder: '1',
        fieldType: 'text',
        withtooltip: true,
        tooltip:
          'The limit refers to records per ID. MapBox can return multiple suggestions sorted by the most probably first. So a limit of 3 would limit MapBox to maximum three suggestion per incoming ID.',
      },
    ],
  },
  toTableau: {
    name: 'toTableau',
    fields: [
      {
        fieldName: 'server_address',
        fieldText: '#1 Input your Tableau Server Address',
        placeholder: 'https://less-prod.online.tableau.com/',
        fieldType: 'text',
      },
      {
        fieldName: 'site_name',
        fieldText: '#2 Input your Tableau Site Name',
        placeholder: 'lessanalytics',
        fieldType: 'text',
      },
      {
        fieldName: 'project_name',
        fieldText: '#3 Input your Project Name',
        placeholder: 'Marketing Analytics Project',
        fieldType: 'text',
      },
      {
        fieldName: 'token_name',
        fieldText: '#4 Input the name of your Tableau Personal Access Token',
        placeholder: '',
        fieldType: 'text',
      },
      {
        fieldName: 'token_value',
        fieldText: '#5 Input the value of your Tableau Personal Access Token',
        placeholder: '',
        fieldType: 'text',
      },
      {
        fieldName: 'hyper_name',
        fieldText: '#6 Name your Hyper file (always finish with .hyper)',
        placeholder: 'someFile.hyper',
        fieldType: 'text',
      },
      {
        fieldName: 'publish',
        fieldText: '#7 Choose write action',
        placeholder: 'Overwrite',
        fieldType: 'dropdown',
        optionType: 'manual',
        options: [
          {
            value: 'overwrite',
            label: 'Overwrite',
          },
          {
            value: 'append',
            label: 'Append',
          },
        ],
      },
      {
        fieldName: 'columns',
        advanced: true,
        fieldText: '#1 Change the data types of your columns',
        fieldType: 'optionsTable',
        optionLabel: 'Columns',
        optionType: 'columns',
        options: 'dataframe',
        prefillValues: 'datatypes',
        defaultValue: {},
        placeholder: 'None',
        // actionLabel: 'Geography Format',
        secondHeader: 'Geography Type',
        types: [
          {
            label: 'None',
            value: '',
          },
          {
            label: 'Point',
            value: 'point',
          },
          {
            label: 'Linestring',
            value: 'linestring',
          },
          {
            label: 'Polygon',
            value: 'polygon',
          },
        ],
      },
    ],
  },
  mapboxIso: {
    name: 'mapboxIso',
    fields: [
      {
        fieldName: 'description',
        fieldText: (
          <p>
            You are responsible for respecting the MapBox{' '}
            <a
              className="border-b-2 border-transparent hover:border-[#5DA6FC]"
              target="_newtab"
              href="https://www.mapbox.com/legal/tos"
            >
              Terms of Service{' '}
            </a>
            .{' '}
          </p>
        ),
        fieldType: 'description',
      },
      {
        fieldName: 'id',
        fieldText: '#1 Select the column that holds an ID',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },

      {
        fieldName: 'lat',
        fieldText: '#2 Select the column containing your latitude',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'lon',
        fieldText: '#3 Select the column containing your longitude',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'routing',
        fieldText: '#4 Select your routing type',
        placeholder: 'Driving (Traffic)',
        fieldType: 'dropdown',
        optionType: 'manual',
        options: [
          {
            value: 'driving',
            label: 'Driving',
          },
          {
            value: 'driving-traffic',
            label: 'Driving (Traffic)',
          },
          {
            value: 'walking',
            label: 'Walking',
          },
          {
            value: 'cycling',
            label: 'Cycling',
          },
        ],
      },
      {
        fieldName: 'contour_type',
        fieldText: '#5 Select your contour type',
        placeholder: 'Minutes',
        fieldType: 'dropdown',
        optionType: 'manual',
        options: [
          {
            value: 'minutes',
            label: 'Minutes',
          },
          {
            value: 'meters',
            label: 'Meters',
          },
        ],
      },
      {
        fieldName: 'contour',
        fieldText: '#6 Select the column containing your contour value',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'accessToken',
        fieldText: '#7 Input your MapBox API token',
        placeholder: '',
        fieldType: 'text',
      },
      {
        fieldName: 'depart_at',
        fieldText:
          'Adjust departure time. Input a datetime value to adjust the polygon based on traffic at that specific time. This features must be enabled on your Mapbox account before you can use it. Must be in this format: yyyy-mm-ddThh:mm.',
        placeholder: '2024-12-24T18:00',
        fieldType: 'text',
        advanced: true,
      },
      {
        fieldName: 'denoise',
        fieldText:
          'Adjust denoise (number between 0-1). Higher value removes smaller contours. Defaults to a value of 1.',
        placeholder: '1',
        fieldType: 'text',
        advanced: true,
      },
      {
        fieldName: 'generalize',
        fieldText:
          'Generalize in meters (number between 0-500). Higher value simplifies the polygon. Defaults to a value of 0.',
        placeholder: '0',
        fieldType: 'text',
        advanced: true,
      },

      {
        fieldName: 'simplify',
        fieldText:
          'Adjust simplicity. Simplifies and shortens your polygon. We suggest numbers between 0-1 with a skew towards small numbers (0.00x). Defaults to a value of 0.0015.',
        placeholder: '0.0015',
        fieldType: 'text',
        advanced: true,
      },
      {
        fieldName: 'buffer',
        fieldText:
          'Adjust buffer. Creates a buffer around your polygon edges to make your polygon smoother. We suggest numbers between 0-2 with a skew towards small numbers (0.0x). Defaults to a value of 0.01.',
        placeholder: '0.01',
        fieldType: 'text',
        advanced: true,
      },
      {
        fieldName: 'round',
        fieldText:
          'Adjust roundness. Increase the roundness of your polygon. We suggest a number between 0-5. Defaults to a value of 2.',
        placeholder: '2',
        fieldType: 'text',
        advanced: true,
      },
    ],
  },
  pointinpoly: {
    name: 'pointinpoly',
    fields: [
      {
        fieldName: 'pointCol',
        fieldText: '#1 Select the column containing your point data',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
        withtooltip: true,
        tooltip: 'Must be a text/string column formatted as GeoJSON',
      },
      {
        fieldName: 'polyCol',
        fieldText: '#2 Select the column containing your polygon data',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
        withtooltip: true,
        tooltip: 'Must be a text/string column formatted as GeoJSON',
      },
    ],
  },
  mapboxDir: {
    name: 'mapboxIso',
    fields: [
      {
        fieldName: 'description',
        fieldText: (
          <p>
            You are responsible for respecting the MapBox{' '}
            <a
              className="border-b-2 border-transparent hover:border-[#5DA6FC]"
              target="_newtab"
              href="https://www.mapbox.com/legal/tos"
            >
              Terms of Service{' '}
            </a>
            .{' '}
          </p>
        ),
        fieldType: 'description',
      },
      {
        fieldName: 'id',
        fieldText: '#1 Select the column that holds an ID',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },

      {
        fieldName: 'originLat',
        fieldText: '#2 Select the column containing your origin latitude',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'originLon',
        fieldText: '#3 Select the column containing your origin longitude',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'destinationLat',
        fieldText: '#4 Select the column containing your destination latitude',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'destinationLon',
        fieldText: '#5 Select the column containing your destination longitude',
        placeholder: 'Select',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'dataframe',
      },
      {
        fieldName: 'routing',
        fieldText: '#6 Select your routing type',
        placeholder: 'Driving (Traffic)',
        fieldType: 'dropdown',
        optionType: 'manual',
        options: [
          {
            value: 'driving',
            label: 'Driving',
          },
          {
            value: 'driving-traffic',
            label: 'Driving (Traffic)',
          },
          {
            value: 'walking',
            label: 'Walking',
          },
          {
            value: 'cycling',
            label: 'Cycling',
          },
        ],
      },

      {
        fieldName: 'accessToken',
        fieldText: '#7 Input your MapBox API token',
        placeholder: '',
        fieldType: 'text',
      },
      {
        fieldName: 'depart_at',
        fieldText:
          'Adjust departure time. Input a datetime value to adjust the polygon based on traffic at that specific time. This features must be enabled on your Mapbox account before you can use it. Must be in this format: yyyy-mm-ddThh:mm.',
        placeholder: '2024-12-24T18:00',
        fieldType: 'text',
        advanced: true,
      },
    ],
  },
  dynamicRename: {
    name: 'dynamicRename',
    fields: [
      // {
      //   fieldName: 'getColumnsFromRow',
      //   fieldText: '#1 Take column names from row',
      //   fieldType: 'checkbox',
      //   defaultValue: {},
      //   withtooltip: true,
      //   tooltip: 'Give the row number you would like to use as headers',
      //   optional: true,
      //   optionType: 'manual',
      //   options: [{
      //       textBefore: 'Take column names from row:',
      //       name: 'rowNumber',
      //       textAfter: '',
      //       inputPlaceholder: '1',
      //       //max: 3,
      //     },
      //   ],
      // },
      {
        fieldName: 'getColumnsFromRow',
        fieldText: '#1 Input the row number that you want to use for column names',
        placeholder: '1',
        fieldType: 'text',
        size: 'small',
        conditions: [
          {
            conditionFieldName: 'df_2',
            condition: '[null, undefined].includes(conditionFieldName)',
          },
        ],
      },
      {
        fieldName: 'fromColumn',
        fieldText: '#1 Change these columns in this incoming data column...',
        placeholder: 'Column',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'df_2',
        optional: true,
        conditions: [
          {
            conditionFieldName: 'df_2',
            condition: '![null, undefined].includes(conditionFieldName)',
          },
        ],
      },
      {
        fieldName: 'toColumn',
        fieldText: '#2 ...to these new column names',
        placeholder: 'Column',
        fieldType: 'dropdown',
        optionType: 'columns',
        options: 'df_2',
        optional: true,
        conditions: [
          {
            conditionFieldName: 'df_2',
            condition: '![null, undefined].includes(conditionFieldName)',
          },
        ],
      },
      // {
      //   fieldName: 'TestingSomething',
      //   fieldText: 'Hvad siger du så?',
      //   placeholder: 'Column',
      //   fieldType: 'dropdown',
      //   optionType: 'columns',
      //   options: 'df_2',
      //   optional: true,
      //   conditions: [{conditionFieldName: "getColumnsFromRow", condition:"conditionFieldName==22"}],
      // },
    ],
  },
  correlation: {
    name: 'correlation',
    fields: [
      {
        fieldName: 'column1',
        fieldText: '#1 Select the first columnn for the correlation',
        fieldType: 'dropdown',
        optionType: 'columns',
        placeholder: 'Column',
        options: 'dataframe',
      },
      {
        fieldName: 'column2',
        fieldText: '#2 Select the second columnn for the correlation',
        fieldType: 'dropdown',
        optionType: 'columns',
        placeholder: 'Column',
        options: 'dataframe',
      },
      {
        fieldName: 'method',
        fieldText: '#3 Select the correlation method',
        placeholder: 'Spearman',
        fieldType: 'dropdown',
        optionType: 'manual',
        options: [
          {
            value: 'spearman',
            label: 'Spearman',
          },
          {
            value: 'pearson',
            label: 'Pearson',
          },
        ],
      },
    ],
  },

  metaInfo: {
    name: 'metaInfo',
    fields: [
      {
        fieldName: 'description',
        fieldText: 'The Describe tool does not require any configuration.',
        fieldType: 'description',
      },
    ],
  },
  // stop: {
  //   name: 'stop',
  //   fields: [
  //     {
  //       fieldName: 'description',
  //       fieldText: 'This Stop action creates an error if there is any data flowing to the tool.',
  //       fieldType: 'description',
  //     },
  //     {
  //       fieldName: 'baseURL',
  //       fieldText: '#1 Insert the error message',
  //       placeholder: '',
  //       fieldType: 'text',
  //     },
  //   ],
  // },
};
